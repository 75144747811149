<template>
    <v-app-bar app hide-on-scroll scroll-threshold="50" flat ref="appBar" :class="[ scrollPosition < scrollTarget ? 'transparent' : 'compliment']" class="custom-font full-width" style="z-index: 1000;">
      <v-container class="full-height d-flex flex-row justify-center">
        
          <v-col cols="12" xl="9" lg="11" md="12" sm="12" xs="12" class="full-height px-0 hidden-sm-and-down">
            <div class="full-width full-height d-flex flex-row justify-space-between align-center">

              <v-col cols="12" xl="2" lg="2" md="2" sm="2">
                <div class="smoke--text font-weight-bold text-h4 cursor--pointer" @click="logoClicked()">DJ</div>
              </v-col>

              <v-col cols="12" xl="8" lg="8" md="8" sm="8" class="d-flex justify-center">
                <nav v-if="showTopNav" class="d-flex flex-row justify-space-between align-center">
                  <div v-for="(nav, index) in navItems" :key="index" class="mx-2">
                    <div class="text-decoration-none font-weight-bold cursor--pointer top-nav-item" @click="$vuetify.goTo(nav.href, scrollOptions)">{{nav.text}}</div>
                    <!-- <router-link class="text-decoration-none font-weight-bold smoke--text" :class="{ 'primary--text' : index === isLinkActive }" :to="nav.href">{{nav.text}}</router-link> -->
                  </div>
                </nav>
              </v-col>

              <v-col cols="12" xl="2" lg="2" md="2" sm="2">
                <div class="d-flex flex-row justify-end align-center">
                  <v-icon small>mdi-moon-waning-crescent</v-icon>
                  <v-switch :color="switchColor" class="mr-n2 ml-2" inset hide-details dense @change="$store.commit('toggleDarkTheme')"></v-switch>
                  <v-icon small>mdi-white-balance-sunny</v-icon>
                </div>
              </v-col>

            </div>
           
          </v-col>

          <v-col cols="12" xl="9" lg="11" md="12" sm="12" xs="12" class="full-height px-0 hidden-md-and-up">
            <div class="full-width full-height d-flex flex-row justify-space-between align-center">
              <div class="smoke--text font-weight-bold text-h4 cursor--pointer" @click="logoClicked()">DJ</div>

              <div class="d-flex flex-row justify-end align-center">
                <v-icon small>mdi-moon-waning-crescent</v-icon>
                <v-switch :color="switchColor" class="mr-n2 ml-2" inset hide-details dense @change="$store.commit('toggleDarkTheme')"></v-switch>
                <v-icon small>mdi-white-balance-sunny</v-icon>
              </div>
            </div>
          </v-col> 

      </v-container>

        
         

    </v-app-bar>
</template>
<script>
export default {
    name: "Navbar",
    props: ["navItems"],
    data: () => ({
      scrollPosition: 0,
      scrollTarget: 64,
      switchColor: 'accent',
      scrollOptions: {
        duration: 500,
        easing: 'easeInOutCubic',
        offset: 0,
      },
    }),
    methods: {
      goToLink(nav){
        console.log("nav", nav)
        // this.$vuetify.goTo(nav, this.scrollOptions)
      },
      updateScroll: function(){
        this.scrollPosition = window.scrollY;
      },
      logoClicked(){
        if(this.$route.path !== '/'){
          this.$router.push({path : '/'}); // go to home page if it isn't on home
        }
        else {
          if(this.isAdmin){
            this.$router.push({ name: 'Admin' });
          }
          else {
            this.$vuetify.goTo('#homeSection', this.scrollOptions) // if it is on home go to the topsection
          }
        }

      }
    },
    mounted(){
      window.addEventListener('scroll', this.updateScroll);
      this.scrollTarget = this.$refs.appBar.$el.clientHeight;
    },
    beforeDestroy(){
      window.removeEventListener('scroll', this.updateScroll);
    },
    computed: {
      isLinkActive(){
        let index = this.navItems.findIndex((nav) =>  nav.href === this.$route.fullPath)
        return index;
      },
      isAdmin(){
        return this.$store.state.isAdmin;
      },
      showTopNav(){
        if(this.$route.name === 'ViewProject'){
          return false
        }
        else if (this.$route.path === '/admin'){
          return false;
        }
        return true
    }
    },
}
</script>
<style lang="scss">

.top-nav-item {
  transition: color .3s ease;
  color: var(--v-smoke-base) !important;
}

.top-nav-item:hover {
  color: var(--v-secondary-lighten2) !important;
}
    
</style>