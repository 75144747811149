<template>
  <v-app>
    <Navbar :navItems="navItems" />
    <v-main class="secondary custom-font">
          <transition :name="transitionName">
            <router-view/>
          </transition>
    </v-main>
    <Alert :showAlert="userAlert.show" />

    <transition name="overlay-fade">
      <v-btn v-show="!showMobileMenu && showScrollUpButton" icon fixed large right bottom class="primary mb-14" style="z-index: 3;" @click="$vuetify.goTo(0, scrollOptions)">
        <v-icon class="white--text">mdi-chevron-up</v-icon>
      </v-btn>
    </transition>

    <MobileNav :navItems="navItems" @changingRoute="changingRoute = true" />
    
    <v-footer app absolute class="secondary lighten-1" height="64px">
      <v-container class="full-height d-flex flex-row justify-center align-center">
        <v-col cols="12" xl="9" lg="11" md="12" sm="12" xs="12" class="px-0 full-height">
          <div class="d-flex flex-row justify-space-between align-center full-width full-height px-md-3">
            <div class="smoke--text font-weight-bold text-h4">DJ</div>
            <div class="text-caption">Inspiration Credited to: Nafie.</div>
          </div>
        </v-col>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Alert from './components/Alert.vue'
import MobileNav from './components/MobileNav.vue'
import firebase from '../firebaseConfig.js'
import { ref, listAll, updateMetadata } from "firebase/storage";

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Dillan Johnson | Software Developer',
    // all titles will be injected into this template
    // titleTemplate: '%s | Dillan Johnson'
  },
  components: {
    Navbar,
    Alert,
    MobileNav
  },
  data() {
    return {
      scrollOptions: {
        duration: 500,
        easing: 'easeInOutCubic',
        offset: 0,
      },
      scrollY: 0,
      changingRoute: false,
      currentScrollPos: 0,
      navItems: [
        { text: "Home", href: "#homeSection", icon: 'mdi-home' },
        { text: "Education", href: "#educationSection", icon: 'mdi-school' },
        { text: "Skills", href: "#skillsSection", icon: 'mdi-xml' },
        { text: "Portfolio", href: "#portfolioSection", icon: 'mdi-briefcase' },
        // { text: "Blog", href: "/blog" },
        // { text: "Admin", href: "/admin" },
        { text: "About", href: "#aboutSection", icon: 'mdi-account-circle' },
        { text: "Contact", href: "#contactSection", icon: 'mdi-message-text' },
      ],
      transitionName: 'slide-right'
    }
  },
  methods: {
    setScroll(){
      this.currentScrollPos = window.scrollY;
    },
    blockScroll(){
      // const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
      window.scrollTo(scrollLeft, this.scrollY);
    },
    detectMobileDevice(){
      // DETECT MOBILE DEVICE
      var hasTouchScreen = false;
      if ("maxTouchPoints" in navigator) {
          hasTouchScreen = navigator.maxTouchPoints > 0;
      } else if ("msMaxTouchPoints" in navigator) {
          hasTouchScreen = navigator.msMaxTouchPoints > 0;
      } else {
          var mQ = window.matchMedia && matchMedia("(pointer:coarse)");
          if (mQ && mQ.media === "(pointer:coarse)") {
              hasTouchScreen = !!mQ.matches;
          } else if ('orientation' in window) {
              hasTouchScreen = true; // deprecated, but good fallback
          } else {
              // Only as a last resort, fall back to user agent sniffing
              var UA = navigator.userAgent;
              hasTouchScreen = (
                  /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                  /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
              );
          }
      }
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.$store.commit('setMobileDevice', true);
        // console.log("mobile device")
      }
      else if (hasTouchScreen){
        this.$store.commit('setMobileDevice', true);
      }
      else{
        this.$store.commit('setMobileDevice', false);
      }
    },
    //admin functions
    updateStorageFileMetadata(){
      const newMetadata = {
        cacheControl: 'public,max-age=604800',
      };
      const listRef = ref(firebase.storage, 'portfolio/');
      listAll(listRef)
      .then((res) => {
        console.log("res", res);
        res.prefixes.forEach((folderRef) => {
          // console.log("folderRef", folderRef);
          listAll(folderRef)
          .then((response) => {
            response.items.forEach((itemRef) => {
              // console.log("itemRef", itemRef)
              // All the items under listRef.
              updateMetadata(itemRef, newMetadata)
              .then((metadata) => {
                console.log("metadata response: ", metadata);
              })
            });

          })
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
        });
        // listAll
      }).catch((error) => {
        // Uh-oh, an error occurred!
        console.log("error retrieving portfolio images", error);
      });
    }
  },
  beforeMount(){
    // console.log("before mount, dark theme: ", this.$vuetify.theme.isDark);
    if(!this.$vuetify.theme.isDark){
      this.$store.commit('toggleDarkTheme');
    }
    this.detectMobileDevice();
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.setScroll);
    window.removeEventListener('resize', this.detectMobileDevice);
  },
  created(){
    this.$store.dispatch('fetchPortfolioItems');
    // console.log(this.$route)
    // const run = false;
    // if(run){
    //   // this.updateStorageFileMetadata();
    // }
  },
  mounted(){
    document.addEventListener('scroll', this.setScroll);
    window.addEventListener('resize', this.detectMobileDevice);
    firebase.auth.onAuthStateChanged(() => { 
        if (firebase.auth.currentUser){
            if(firebase.auth.currentUser.uid === process.env.VUE_APP_ADMIN_ID){
              // console.log(process.env.VUE_APP_ADMIN_ID)
              this.$store.commit('setAdmin', true);
              this.$setLoggerAdmin(true)
            }
            else {
              this.$store.commit('setAdmin', false);
            }
        }
        else {
          // const index = this.navItems.findIndex( item => item.text === 'Admin');
          // if(index !== -1){
          //   this.navItems.splice(index, 1);
          // }
          this.$store.commit('setAdmin', false);
        }
    });
  },
  watch: {
    'showMobileMenu' (newValue){
      if(newValue){
        this.scrollY = window.scrollY;
        document.addEventListener('scroll', this.blockScroll);
      }
      else {
        document.removeEventListener('scroll', this.blockScroll);
      }
      this.changingRoute = false;
    },
    '$route' (to, from) {
      let toDepth = to.path.split('/').length
      let fromDepth = from.path.split('/').length
      
      if(to.path.split('/')[1] === ''){
        toDepth -= 1;
      }
      if(from.path.split('/')[1] === ''){
        fromDepth -= 1;
      }
      // console.log("to Depth", toDepth)
      // console.log("from Depth", fromDepth)
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    }
  },
  computed: {
    portfolioItems(){
      return this.$store.state.portfolioItems;
    },
    userAlert(){
      return this.$store.state.alert;
    },
    showMobileMenu(){
      return this.$store.state.userInterface.showMobileMenu;
    },
    isLinkActive(){
      let index = this.navItems.findIndex((nav) =>  nav.href === this.$route.fullPath)
      return index;
    },
    headerHeight(){
      return this.$store.state.userInterface.headerHeight;
    },
    showScrollUpButton(){
      if (this.currentScrollPos > this.headerHeight){
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss">
.custom-font {
  font-family: 'Poppins', sans-serif;
}

.v-main {
  transition: background .9s cubic-bezier(.4,0,.2,1) !important;
}


/* transitions */
.slide-right-enter-active, .slide-right-leave-active {
  transition: transform .3s;
}
.slide-right-enter, .slide-right-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
}

.slide-left-enter-active, .slide-left-leave-active {
  transition: transform .3s;
}
.slide-left-enter, .slide-left-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(-100%);
}



//////// TEXT SHADOWS /////////



</style>
