<template>
  <div class="full-height d-flex flex-column align-center justify-space-between red-border">

    <!-- DIXIE STATE UNIVERSITY -->

    <div class="d-flex flex-row justify-center justify-lg-space-between align-start flex-wrap full-width mb-16">
      <v-col xl="5" lg="5" md="12" sm="12" xs="12" class="pt-0">
          <v-img src="../assets/dsu.jpeg" lazy-src="../assets/dsu-min.jpeg" ref="dsuImg" cover max-height="400"></v-img>
      </v-col>

      <v-col xl="6" lg="6" md="12" sm="10" xs="12">
          
          <!-- <SectionTextBlock :class="[isIntersecting ? 'slide-up-fade-in' : 'slide-up-fade-out']" :rotatedText="textBlocks[0].rotatedText" :heading="textBlocks[0].heading" :subheading="textBlocks[0].subheading" :body="textBlocks[0].body"/> -->
          <div :class="[isIntersecting ? 'slide-up-fade-in' : 'slide-up-fade-out']" class="d-flex flex-md-row flex-column align-md-start align-start justify-start full-height" ref="dsuText">

            <div class="hidden-sm-and-down rotated-text rotate-180 text-subtitle-1 mt-3 mr-3 smoke--text">Dixie State University</div>
            <div class="text-subtitle-2 mt-3 smoke--text d-flex flex-row justify-start align-center">
              <div class="hidden-md-and-up long-line mr-4 flex-grow-1 smoke"></div>
              <span class="hidden-md-and-up ">Dixie State University</span>
              <v-spacer class="hidden-md-and-up"></v-spacer>
            </div>

            <!-- <v-col cols="12" sm="11" xs="12" class="pa-0"> -->
              <div class="d-flex flex-column">
                <h1 class="primary--text hidden-xs-only heading-text">Computer Information Technology <br> Software Development Emphasis</h1>
                <h3 class="primary--text hidden-sm-and-up heading-text">C.I.T <br> Software Development Emphasis</h3>
                <h4 class="primary--text text--darken-2 my-2 hidden-xs-only subheading-text">Web Development Minor</h4>
                <h5 class="primary--text text--darken-2 my-2 hidden-sm-and-up subheading-text">Web Development Minor</h5>
                <div class="smoke--text text--darken-1 body-text full-width">I graduated with a Bachelors of Science in <a class="smoke--text text--darken-1" href="https://catalog.dixie.edu/previouscatalogs/2020-2021/programs/computing-and-design/computer-information-technology-software-development-bs/" target="_blank">Computer and Information Technology, Software Development Emphasis</a>; with an overall GPA of 3.68, and Cum Laude Honors. Through my hard work and effort I was chosen to be a recipient of the Atkins Foundation Scholarship. I chose the CIT Software Development Degree because of my obsession with becoming a software developer. I chose this degree because it was extremely similar to the Computer Science Degree, but allowed me to focus more on computer programming, and software development principles and architecture. My passion for web design and development led me to the decision to minor in web development while attending Dixie State University.</div>
              </div>
            <!-- </v-col> -->

          </div>

        <!-- <div style="min-height: 462.4px; position: absolute; top: 0; left: 0;"></div> -->

      </v-col>

    </div>
    <!-- END: DIXIE STATE UNIVERSITY -->


    <!-- BATC -->
    <div class="d-flex flex-row justify-center justify-lg-space-between align-end flex-wrap-reverse full-width mt-lg-16">

      <v-col xl="6" lg="6" md="12" sm="10" xs="12"> 
        <!-- <SectionTextBlock :class="[isIntersecting ? 'slide-up-fade-in' : 'slide-up-fade-out']" :rotatedText="textBlocks[1].rotatedText" :heading="textBlocks[1].heading" :subheading="textBlocks[1].subheading" :body="textBlocks[1].body"/> -->
          <div :class="[isIntersecting ? 'slide-up-fade-in' : 'slide-up-fade-out']" class="d-flex flex-md-row flex-column align-md-start align-start justify-start full-height" style="transition-delay: 0.5s;" ref="batcText">

            <div class="hidden-sm-and-down rotated-text rotate-180 text-subtitle-1 mt-3 mr-3 smoke--text">Bridgerland Technical College</div>
            <div class="text-subtitle-2 mt-3 smoke--text d-flex flex-row justify-start align-center">
              <div class="hidden-md-and-up long-line mr-4 flex-grow-1 smoke"></div>
              <span class="hidden-md-and-up ">Bridgerland Technical College</span>
              <v-spacer class="hidden-md-and-up"></v-spacer>
            </div>

            <div class="d-flex flex-column">
              <h1 class="primary--text hidden-xs-only heading-text">Media Design Certificate</h1>
              <h3 class="primary--text hidden-sm-and-up heading-text">Media Design Certificate</h3>
              <h4 class="primary--text text--darken-2 my-2 hidden-xs-only subheading-text">Graphic Design</h4>
              <h5 class="primary--text text--darken-2 my-2 hidden-sm-and-up subheading-text">Graphic Design</h5>
              <div class="smoke--text text--darken-1 body-text full-width">I have always had a passion for creating content via the computer. Growing up, I dreamed of creating the stunning visuals seen on TV, in magazines, etc. This led me to learning Adobe Photoshop, the program where I spent a lot of my free time creating visual works in High School. My passion and experience showed execs at Bridgerland Technical College that I was deserving of a full ride scholarship to their <a class="smoke--text text--darken-1" href="https://btech.edu/media-design/" target="_blank">Media Design Program.</a> During my time studying at Bridgerland, I learned how to use the Adobe Creative Suite and learned how to build visual layouts using strong design principles. After successfully implementing my new skills, I used them to create the visuals for projects -- specifically web and mobile applications. This led to my obsession with learning how to program my designs to make them a completely functional application.</div>
            </div>
          </div>
        <!-- <div style="min-height: 355.5px; position: absolute; top: 0; left: 0;"></div> -->
      </v-col>

      <v-col cols="12" xl="5" lg="5" md="12" sm="12" xs="12" class="pt-0">
        <v-img src="../assets/batc.jpg" lazy-src="../assets/batc-min.jpg" cover ref="batcImg" max-height="400" position="center center"></v-img> 
        <!-- TODO: SET THE IMAGES HEIGHT DYNAMICALLY TO THE SAME AS THE TEXT COL && LAZY LOAD THE IMAGES/OPTIMIZE THE IMAGES! -->
      </v-col>
    </div>

    <!-- END:  BATC -->

    <!-- CONTINUED LEARNING -->

    <!-- <div class="d-flex flex-column justify-space-between align-start full-width mt-16">

      <v-col xl="6" lg="6" md="6" sm="12" xs="12">
        <div class="d-flex flex-row justify-start align-center mx-0 full-width">
          <h1 class="primary--text hidden-xs-only">"Always Be Learning"</h1>
          <h3 class="primary--text hidden-sm-and-up">"Always Be Learning"</h3>
          <div class="long-line flex-grow-1"></div>
          <v-spacer></v-spacer>
        </div>
      </v-col>

      <v-col xl="6" lg="6" md="6" sm="12" xs="12">
        <div>
          <h4>Heading</h4>
          <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto quos aliquam vel harum eveniet? Voluptatem, et. Quaerat quos est fuga esse voluptates quam soluta suscipit eius illum voluptatibus mollitia veniam nihil, labore vero dolor numquam ex recusandae quibusdam nulla et!</div>
        </div>
      </v-col>

      <v-col xl="12" xs="12">
        <div class="d-flex flex-row justify-space-between align-center flex-wrap">
          <template v-for="(course, index) in courses">
            <LearningCard :key="index" :feature="course" />
          </template>

          <div class="learning-icon rounded-circle primary d-flex flex-row justify-center align-center pa-3">
            <v-icon>mdi-language-java</v-icon>
          </div>

          <div class="learning-icon rounded-circle purple d-flex flex-row justify-center align-center pa-3">
            <v-icon>mdi-language-swift</v-icon>
          </div>

          <div class="learning-icon rounded-circle green darken-1 d-flex flex-row justify-center align-center pa-3">
            <v-icon>mdi-message</v-icon>
          </div>

          <div class="learning-icon rounded-circle blue d-flex flex-row justify-center align-center pa-3">
            <v-icon>mdi-chart-line</v-icon>
          </div>

        </div>
      </v-col>
    </div> -->

    <!-- END: CONTINUED LEARNING -->


      
  </div>
</template>
<script>
import LearningCard from './LearningCard.vue';
// import SectionTextBlock from './SectionTextBlock.vue';

export default {
  name: "Education",
  components: {
    LearningCard,
    // SectionTextBlock,
  },
  props:["isIntersecting"],
  data: () => ({
    textBlocks: [
      {
        rotatedText: "Dixie State University",
        heading: "C.I.T, Software Development Emphasis",
        subheading: "Web Development Minor",
        body: 'I graduated with a Bachelors of Science in <a class="smoke--text text--darken-1" href="https://catalog.dixie.edu/previouscatalogs/2020-2021/programs/computing-and-design/computer-information-technology-software-development-bs/" target="_blank">Computer and Information Technology, Software Development Emphasis</a>; with an overall GPA of 3.68. Through my hard work and effort I was chosen to be a recipient of the Atkins Foundation Scholarship. I chose the CIT Software Development Degree because of my interest in learning how to become a software developer. Choosing this degree allowed me to focus and enroll in more computer programming courses, rather than some of the advanced Physics and Mathematics courses typically required for Computer Science. My passion for web design and development led me to the decision to minor in web development while attending Dixie State University.',
      },
      {
        rotatedText: "Bridgerland Technical College",
        heading: "Media Design Certificate",
        subheading: "Graphic Design",
        body: `I have always had a passion for creating content via the computer. Growing up, I wanted to be able to create the stunning visuals seen on TV, in magazines, etc. This led me to learning Adobe Photoshop, the program where I spent a lot of my free time creating visual works in High School. My passion and experience showed execs at Bridgerland Technical College that I was deserving of a full ride scholarship to their &#60 href="https://btech.edu/media-design/" target="_blank"&#62 Media Design Program.</a> During my time studying at Bridgerland, I learned how to use the Adobe Creative Suite and learned how to build visual layouts using strong design principles.`,
      }
    ],
    // courses: [
    //   { 
    //     title: "Title",
    //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit, doloribus molestias? Ipsa amet libero expedita. Eos laudantium nobis fuga voluptatem voluptates. Nulla asperiores sit quos amet, dolores iure animi rerum.",
    //     icon: "mdi-language-swift"
    //   },
    //   { 
    //     title: "Title",
    //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit, doloribus molestias? Ipsa amet libero expedita. Eos laudantium nobis fuga voluptatem voluptates. Nulla asperiores sit quos amet, dolores iure animi rerum.",
    //     icon: "mdi-language-java"
    //   },
    //   { 
    //     title: "Title",
    //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit, doloribus molestias? Ipsa amet libero expedita. Eos laudantium nobis fuga voluptatem voluptates. Nulla asperiores sit quos amet, dolores iure animi rerum.",
    //     icon: "mdi-chart-line"
    //   },
    //   { 
    //     title: "Title",
    //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit, doloribus molestias? Ipsa amet libero expedita. Eos laudantium nobis fuga voluptatem voluptates. Nulla asperiores sit quos amet, dolores iure animi rerum.",
    //     icon: "mdi-message"
    //   },
    // ],
  }),
  methods: {
    getUdemyCourses(){
      this.$http.get(`http://localhost:8000/udemy/courses`)
      .then((response) => {
        console.log("response: ", response.data);
      })
    },
    setImageHeight(){
      console.log(this.$refs.dsuImg)
      this.$refs.dsuImg.$options.propsData['height'] = this.$refs.dsuText.clientHeight;
      // console.log(this.$refs.dsuImg.$el.style.height)
      // console.log(this.$refs.dsuText.clientHeight);
      this.$refs.dsuImg.$el.style.height = this.$refs.dsuText.clientHeight;

      this.$refs.batcImg.$options.propsData['height'] = this.$refs.batcText.clientHeight;
      // console.log(this.$refs.dsuImg.$el.style.height)
      // console.log(this.$refs.dsuText.clientHeight);
      this.$refs.batcImg.$el.style.height = this.$refs.batcText.clientHeight;
    }

  },
  mounted(){
    // this.setImageHeight();

  },
  created(){
    // this.getUdemyCourses();

  },
  computed: {

  },
}
</script>
<style>

.bar-before-title::before {
  content: "";
  display: inline-block;
  height: 2px;
  width: 60px;
  /* margin-bottom: 50%; */
  background-color: var(--v-smoke-base) !important;
  /* writing-mode: vertical-rl; */
}

.rotated-text {
  writing-mode: vertical-rl;
}

.rotated-text::before {
  content: "";
  display: inline-block;
  max-height: 120px;
  min-height: 60px;
  width: 2px;
  margin-bottom: 10px;
  background-color: var(--v-smoke-base) !important;
  transform: rotate(180deg);
}

.long-line {
  height: 2px;
  width: 60px;
  /* background-color: var(--v-smoke-base) !important */
}

.slideUp-enter-active {
  transition: all .5s ease;
}
.slideUp-leave-active {
  transition: all .15s ease;
}
.slideUp-enter, .slideUp-leave-to {
  opacity: 0;
  /* position: absolute; */
  transform: translateY(15px);
}

.slide-up-fade-in {
  transition: all .8s ease;
  opacity: 1;
  transform: translateY(-20px);
}

.slide-up-fade-out {
  transition: all .5s ease;
  opacity: 0;
  display: none !important;
  transform: translateY(20px);
}
    
</style>