<template>
  <div class="full-height red-border">
    <div class="d-flex flex-row justify-center justify-md-space-between align-start flex-wrap full-width">
      
      <v-col cols="12" xl="5" lg="5" md="6" sm="10" xs="12">
        <SectionTextBlock :class="[isIntersecting ? 'slide-up-fade-in' : 'slide-up-fade-out']" :rotatedText="sectionText.rotatedText" :heading="sectionText.heading" :subheading="sectionText.subheading" :body="sectionText.body" />
        <!-- <transition name="slideUp"> -->
          <!-- <div :class="[isIntersecting ? 'slide-up-fade-in' : 'slide-up-fade-out']" class="d-flex flex-md-row flex-column align-md-start align-start justify-start full-height skills-text-block" >
            <div class="hidden-sm-and-down rotated-text rotate-180 text-subtitle-1 mt-3 mr-3 smoke--text">My Skills</div>
            <div class="text-subtitle-2 mt-3 smoke--text d-flex flex-row justify-start align-center">
              <div class="hidden-md-and-up long-line mr-4 flex-grow-1 smoke"></div>
              <span class="hidden-md-and-up ">My Skills</span>
              <v-spacer class="hidden-md-and-up"></v-spacer>
            </div>

            <v-col cols="12" sm="11" xs="12" class="pa-0">
              <h1 class="primary--text mb-3 hidden-xs-only">My Programming Skills</h1>
              <h3 class="primary--text mb-3 hidden-sm-and-up">My Programming Skills</h3>
              <div class="smoke--text text--darken-1 text-subtitle-1 text-lg-body-1">Few people that develop projects also have strong design fundamentals; which is where I differ. My design background is used in conjunction with my programming skills to develop simple, intuitive and visually appealing user interface, with a core focus on user experience; which helps users get things done with less effort and time. I do this by using the best and newest tools available to developers; whilst staying up to date on current technologies.</div>
            </v-col>
          </div> -->
        <!-- </transition> -->
        <!-- <div style="min-height: 272px; position: absolute; top: 0; left: 0;"></div> -->

      </v-col>

      <v-col cols="12" xl="6" lg="6" md="6" sm="10" xs="12" class="pa-0 pt-2 pt-md-0">
        <!-- <v-img src="../assets/dsu.jpeg" cover></v-img> -->
        <div class="skills-icons-wrapper full-width d-flex flex-row flex-wrap justify-center align-center">
          <template v-for="(skill,index) in skills">
            <!-- <v-col :key="index" xl="3"> -->
              <!-- <v-hover v-slot="{hover}" > -->
                <!-- <v-icon>{{skill.icon}}</v-icon> -->
                <div v-if="isIntersecting" :key="index" class="skill-icon rounded-lg compliment d-flex justify-center align-center mb-4 mx-2 pa-2 elevation-6" :style="`animation-delay: ${(index + 1) * 50}ms;`">
                    <v-img :src="skill.icon" contain class="skill-logo"></v-img>
                    <div class="skill-name-tooltip white--text font-weight-bold rounded elevation-6 caption-text" :class="skill.color">{{skill.name}}
                      <div class="skill-name-tooltip-arrow elevation-6" :class="skill.color"></div>
                    </div>
                </div>
                <!-- <div v-show="hover" class="hidden-xs-only"></div> -->
              <!-- </v-hover> -->
              
            <!-- </v-col> -->
          </template>
        </div>
      </v-col>

    </div>
  </div>
</template>
<script>
// require('../assets/logos');
import SectionTextBlock from './SectionTextBlock.vue'
export default {
  name: "Skills",
  props: ["isIntersecting"],
  components: {
    SectionTextBlock,
  },
  data: () => ({
    skills: [
      // TODO: make your own logos to get away from copyright shiiit
      { 'name': "Javascript", 'icon': require('../assets/logos/javascript.png'), color: "yellow darken-2", showTooltip: false },
      { 'name': "HTML 5", 'icon': require('../assets/logos/html5.png'), color: "orange darken-3", showTooltip: false },
      { 'name': "CSS 3", 'icon': require('../assets/logos/css3.png'), color: "blue darken-2", showTooltip: false },
      { 'name': "VueJS", 'icon': require('../assets/logos/vue.png'), color: "green lighten-", showTooltip: false },
      { 'name': "NodeJS", 'icon': require('../assets/logos/node.png'), color: "green darken-3", showTooltip: false },
      { 'name': "GCP", 'icon': require('../assets/logos/google_cloud_platform.png'), color: "blue darken-1", showTooltip: false },
      { 'name': "Firebase", 'icon': require('../assets/logos/firebase.png'), color: "orange", showTooltip: false },
      { 'name': "Python", 'icon': require('../assets/logos/python.png'), color: "blue darken-4", showTooltip: false },
      { 'name': "Java", 'icon': require('../assets/logos/java.png'), color: "orange darken-4", showTooltip: false },
      { 'name': "C++", 'icon': require('../assets/logos/c++.png'), color: "blue darken-3", showTooltip: false },
      { 'name': "Adobe CC", 'icon': require('../assets/logos/adobe_cc.png'), color: "red darken-1", showTooltip: false },
      { 'name': "Github", 'icon': require('../assets/logos/github.png'), color: "grey darken-4", showTooltip: false },
    ],
    sectionText: {
      rotatedText: "My Skills",
      heading: "My Programming Skills",
      subheading: "",
      body: "Few Web Developers and Software Developers also have strong design fundamentals; which is where I differ. My design background is used in conjunction with my programming skills to develop small, simple applications; or large, complex applications using strong programming fundamentals. With my projects I emphasize creating intuitive and visually appealing user interface, with a core focus on user experience. This results in lightning fast applications that helps users get things done with less effort and time. I do this by using the best and newest tools available to developers; while staying up to date on current technologies.",
    },
  }),
  watch: {
    // isIntersecting(newValue, oldValue){
      // console.log("isIntersecting, ", oldValue, newValue)
    // }
  },
  methods: {
    // showTooltip(index){
    //   console.log("showTooltip()", this.isMobileDevice)
    //   if(!this.isMobileDevice){ return; } // only use click events to show the tooltip if it is a mobile device
    //   this.skills.forEach((skill, i) => {
    //     console.log("i: ", i)
    //     console.log("index", index)
    //     if(i === index){
    //       skill.showTooltip = true;
    //       console.log("showing index: ", i)
    //     }
    //     else {
    //       skill.showTooltip = false;
    //     }
    //     setTimeout(() => {
    //       console.log("setting to false in timeout")
    //       this.skills[index].showTooltip = false;
    //     }, 3000)
    //   })
    // }
  },
  created(){

  },
  computed: {
    isMobileDevice(){
      return this.$store.state.isMobileDevice;
    }
  },
}
</script>
<style>
.skills-icons-wrapper{
  min-height: 220px;
  /* max-height: 470px; */
}
.skill-icon {
  position: relative;
  width: 95px;
  height: 95px;
  transform-origin: center;
  animation: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
  animation-name: slideInFromLeft;
  visibility: none;
  transition: background 0.9s cubic-bezier(.4,0,.2,1);
  user-select: none;
}


.skill-logo {
  width: 70% !important;
  height: 70%;
}

.skill-name-tooltip {
  position: absolute;
  left: 50%;
  min-width: max-content;
  top: 0;
  transform: translate(-50%, -130%);
  animation: slideDown .3s ease;
  padding: 0.8rem 1rem;
  z-index: 1;
  display: none;
}

.skill-name-tooltip-arrow {
  transform: translate(-50%, 100%);
  left: 50%;
  bottom: 1px;
  height: 10px;
  width: 15px;
  -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  content: "";
  position: absolute;
  display: none;
}

.skill-icon:hover .skill-name-tooltip, .skill-name-tooltip-arrow {
  display: inline-block;
}

.fade-enter-active {
  transition: opacity 2.5s;
}
.fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@keyframes slideDown {
  from {
    transform: translate(-60%, -200%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -130%);
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: scale(0.75, 0.75);
    visibility: visible;
    opacity: 0;
  }
  50%{
    transform: scale(1.1, 1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
    visibility: visible;
  }
}

/* XS */
@media (max-width: 600px){
  .skills-icons-wrapper{
    min-height: 444px;
    max-height: 100%;
  }

}

/* SM */
@media (min-width: 601px) and (max-width: 960px){
  .skills-icons-wrapper{
    min-height: 330px;
  }
}

/* MD */
@media (min-width: 961px) and (max-width: 1264px){
  .skills-icons-wrapper{
    min-height: 333px;
  }
}

/* LG */
@media (min-width: 1265px) and (max-width: 1904px){
   .skills-icons-wrapper{
    min-height: 333px;
  }
}

/* XL */
@media (min-width: 1905px){
  .skills-icons-wrapper{
    height: 222px;
  }
}


</style>