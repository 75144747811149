import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueTilt from 'vue-tilt.js'
import firebase from "../firebaseConfig.js";
import VueMeta from 'vue-meta'


// my stuff
import logger from "./logger.js";
require('./styles/main.css')
require('./styles/shadows.css')

Vue.config.productionTip = false

Vue.prototype.$http = axios;

Vue.prototype.$log = (p1, p2, p3) => {
  logger.log(p1,p2,p3);
};

Vue.prototype.$setLoggerAdmin = (admin) => {
  logger.setAdmin(admin);
}

Vue.use(VueMeta)
Vue.use(VueTilt)

let app;
firebase.auth.onAuthStateChanged(() => {
  // if it is an admin (me) or we are in dev mode, disable!
    if(firebase.auth.currentUser){
      window['ga-disable-292903234'] = true;
    }
    else if (process.env.NODE_ENV !== 'production'){
      console.log("dev, disable")
      window['ga-disable-292903234'] = true;
    }
    
    if (!app){
      app = new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
      }).$mount("#app");
    }
});