<template>
<div>
  <header class="hero d-flex flex-row justify-center align-end align-lg-center" id="homeSection" v-intersect="{ handler: onHeaderIntersect, options: {  threshold: [0, 0.25, 0.5, 0.75, 1.0] } }">
      <!-- <div v-if="!showHeadshotHero" id="hero-img-background" class="hidden-xs-only">
        <div id="c1"></div>
        <div id="c2"></div>
      </div> -->
 <!-- STOCK IMAGES -->
    <!-- <div v-if="!showHeadshotHero" class="hero-img">
      <div class="layer">
        <img id="layer-1" src="../assets/hero_img_no_background-min.png"  loading="eager" alt="Dillan Johnson">
      </div>
    </div> -->
<!-- HEADSHOT -->
    <div class="hero-img">
      <!-- mask layer -->
      <div class="headshot-layer" :class="[ headerIntersecting ? 'headshot-scale-up' : 'headshot-scale-down' ]" ref="headshotLayerTop"> 
        <img class="headshot-img" 
        :src="require('../assets/hero_portrait_xl-min.png')"
        srcset="../assets/hero_portrait_sm-min.png 656w, ../assets/hero_portrait_lg-min.png 1750w, ../assets/hero_portrait_xl-min.png 2560w"
        sizes="(max-width: 960px) 100vw, (max-width: 1904px) 100vw, 100vw"
        loading="eager" 
        alt="Dillan Johnson"
        >
      </div>
      <!-- top portion -->
      <!-- :style="`transform: translate(${heroAnimations.moveHeadshotX}px, ${heroAnimations.moveHeadshotY}px)`" -->
      <div class="headshot-layer" ref="headshotLayerBottom" >
        <img class="headshot-img" :class="[ headerIntersecting ? 'headshot-scale-up' : 'headshot-scale-down' ]"
        src="../assets/hero_portrait_xl-min.png"
        srcset="../assets/hero_portrait_sm-min.png 656w, ../assets/hero_portrait_lg-min.png 1750w, ../assets/hero_portrait_xl-min.png 2560w"
        sizes="(max-width: 960px) 100vw, (max-width: 1904px) 100vw, 100vw"
        loading="eager" 
        alt="Dillan Johnson"
        >
      </div>
    </div>
<!-- HERO TEXT -->
    <v-container class="d-flex flex-row justify-center mb-md-16" style="z-index: 4;">
      <v-col cols="12" xl="9" lg="11" md="12" sm="11" class="px-0">
        <div class="d-flex flex-column justify-center align-start hero-text smoke--text text-uppercase">

          <v-col cols="12" xl="6" lg="6" md="12" sm="12" xs="12" class="d-flex justify-center justify-lg-start">
            <!-- <h1 class="mb-8 text-h2 font-weight-bold text-center text-lg-left text-shadow">I Design & Build <br> Creative Products</h1> -->
            <h1 class="text-hero-headline font-weight-bold text-center text-lg-left full-width smoke--text text--elevation-6">Dillan Johnson.</h1>
          </v-col>

          <!-- <v-col cols="12" xl="6" lg="6" md="12" sm="12" xs="12" class="d-flex justify-lg-start justify-center align-start"> -->
          <div class="px-3 align-self-center align-self-lg-start">
            <h3 :class="[showSubtitle ? 'typewriter type-forward' : 'typewriter type-backward' ]" class="text-hero-subheadline">{{heroSubtitleOptions[subtitleIndex]}}</h3>
            <!-- <h3 v-else class="hero-subtitle py-4"> </h3> -->
          </div>
          <!-- </v-col> -->


          <v-col cols="12" xl="6" lg="6" md="12" sm="12" xs="12" class="d-flex mt-8 justify-lg-start justify-center">
            <button type="button" class="button-primary align-self-center button-xlarge" @click="$vuetify.goTo('#contactSection', scrollOptions)">Let's Talk</button>
          </v-col>
          <!-- <v-btn outlined depressed color="smoke">
            Get In Touch
          </v-btn> -->
        </div>
      </v-col>
    </v-container>
  </header>

  <transition name="fade">
    <div v-if="showScrollIndicator && lastScrollPosition < userInterface.headerHeight" class="scroll-more-indicator">
      Scroll <br> v
    </div>
  </transition>
  

<!-- <v-expand-transition> -->
  <!-- TODO: LET ALL OVERALL CONSISTENT PADDING BE SET HERE! ON V-CONTAINER AND V-COL-->
  <v-container class="d-flex flex-row justify-center" style="z-index: 2;">
    <v-col cols="12" xl="9" lg="11" md="12" sm="12" class="px-0">

      <v-row class="full-width py-8 mx-0 my-8" justify="space-between" align="center">
        <template v-for="(feature, index) in features">
          <v-col :key="index" xl="4" lg="4" md="6" sm="6" xs="12" class="feature-card-container">
            <FeatureCard :feature="feature"/>
          </v-col>
        </template>
      </v-row>

      <section id="educationSection" class="my-16 py-md-16 py-sm-8 py-0 blue-border" v-intersect="{ handler: onEducationIntersect, options: {  threshold: [0, 0.25, 0.5, 0.75, 1.0] } }">
        <Education :isIntersecting="educationIntersecting" />
      </section>

      <section id="skillsSection" class="my-16 py-md-16 py-sm-8 py-0 d-flex justify-center align-center blue-border " v-intersect="{ handler: onSkillsIntersect, options: {  threshold: [0, 0.25, 0.5, 0.75, 1.0] } }">
        <Skills :isIntersecting="skillsIntersecting" />
      </section>

      <section id="portfolioSection" class="blue-border my-16 py-md-16 py-sm-8 py-0 d-flex justify-center align-center" v-intersect="{ handler: onPortfolioIntersect, options: {  threshold: [0, 0.25, 0.5, 0.75, 1.0] } }">
        <Portfolio :isIntersecting="portfolioIntersecting"/>
      </section>

      <section id="aboutSection" class="blue-border my-16 py-md-16 py-sm-8 py-0" v-intersect="{ handler: onAboutIntersect, options: {  threshold: [0, 0.25, 0.5, 0.75, 1.0] } }">
        <About :isIntersecting="aboutIntersecting" />
      </section>

      <section id="contactSection" class="blue-border mt-16 mb-8 py-md-16 py-sm-8 py-0">
        <Contact />
      </section>

    </v-col>
  </v-container>
  <!-- </v-expand-transition> -->

</div>
</template>

<script>
  // import Navbar from '../components/Navbar.vue'
  import Skills from '../components/Skills.vue'
  import Contact from '../components/Contact.vue'
  import Education from '../components/Education.vue'
  import About from '../components/About.vue'
  import Portfolio from '../components/Portfolio.vue'
  import FeatureCard from '../components/FeatureCard.vue'
  export default {
    name: 'Home',
    components: {
      About,
      Skills,
      Contact,
      Education,
      Portfolio,
      FeatureCard,
    },
    data: () => ({
      showHeadshotHero: true, // use this to deploy with two different versions and a/b test with hero image!
      headerIntersecting: false,
      educationIntersecting: false,
      aboutIntersecting: false,
      skillsIntersecting: false,
      portfolioIntersecting: false,
      showScrollIndicator: true,
      features: [
        { title: "9", href: "#portfolioSection", subtitle: ["Years of Experience"], icon: "mdi-ribbon" },
        { title: "B.S, CIT", subtitle: ['Software Developer Emphasis', 'Minor in Web Development'], href: "#educationSection", icon: "mdi-school" },
        { title: "Skills", href: "#skillsSection", subtitle: ["Programming"], icon: "mdi-hammer" },
      ],
      scrollOptions: {
        duration: 500,
        easing: 'easeInOutCubic',
        offset: 0,
      },
      heroSubtitle: "Software Developer",
      subtitleIndex: 0,
      heroSubtitleOptions: [
        "Software Developer.",
        "Full Stack Developer.",
        "UI/UX Designer.",
        "Graphic Designer.",
        "Software Engineer.",
      ],
      showSubtitle: true,
      heroInterval: null,
      gradientAdjustment: 100,
      lastScrollPosition: 0,
      heroAnimations: {
        moveHeashotX: 0,
        moveHeashotY: 0,
        lastX: 0,
        lastY: 0,
        l: false,
        r: false,
        d: false,
        u: false,
      }
    }),
    methods: {
      onHeaderIntersect(entries){
        this.headerIntersecting = entries[0].intersectionRatio >= 0.45;
      },
      onEducationIntersect(entries, observer){
        // console.log("intersection")
        // console.log(entries);
        // console.log(entries[0].intersectionRation)
        // console.log(observer)
        // console.log(entries[0].intersectionRatio)
        this.educationIntersecting = entries[0].intersectionRatio >= 0.15
      },
      onAboutIntersect(entries){
        this.aboutIntersecting = entries[0].intersectionRatio >= 0.15
      },
      onSkillsIntersect(entries){
        this.skillsIntersecting = entries[0].intersectionRatio >= 0.15
      },
      onPortfolioIntersect(entries){
        this.portfolioIntersecting = entries[0].intersectionRatio >= 0.15
      },
      setHeaderHeight(){
        const header = document.querySelector('#homeSection');
        this.$store.commit('setHeaderHeight', header.offsetHeight);
      },
      setNewHeroSubtitle(){
        // console.log("running new subtitle!")
        clearInterval(this.heroInterval);
        this.showSubtitle = false;
        setTimeout( () => {
          this.subtitleIndex += 1;
          if(this.subtitleIndex >= this.heroSubtitleOptions.length){
            this.subtitleIndex = 0; // start it back over.
          }
          this.heroSubtitle = this.heroSubtitleOptions[this.subtitleIndex]
          this.showSubtitle = true;
          this.heroInterval = setInterval(this.setNewHeroSubtitle, 5500);
        }, 2000)
      },
      animateOnScroll(e){
        // console.log("e", e);
        const background = document.querySelector('#hero-img-background');
        if(window.scrollY > this.userInterface.headerHeight){ // don't aniamt on when it is off the screen
          // console.log(window.scrollY ," , ", this.userInterface.headerHeight);
          // this.gradientAdjustment = 0;
          // background.style.backgroundPosition = '0% 0%';
          return;
        }
        if(window.scrollY < this.lastScrollPosition){ // scrolling up
          this.gradientAdjustment += 1.5;
        }
        else { // scrolling down
          this.gradientAdjustment -= 1.5;
        }
        // respect the bounds 0 - 100 %
        if(this.gradientAdjustment > 100){
          this.gradientAdjustment = 100;
        }
        else if(this.gradientAdjustment < 0){
          this.gradientAdjustment = 0;
        }
        background.style.backgroundPosition = `${this.gradientAdjustment}% ${this.gradientAdjustment}%`;
        this.lastScrollPosition = window.scrollY;
        return;
      },
      handleHeroOffHover(){
        console.log("offHover()")
        this.heroAnimations.moveHeadshotX = 0;
        this.heroAnimations.moveHeadshotY = 0;
      },
      handleHeroHover(event){ // used to handle the skewying on hover
        // 1 -  find out movement direction
        const x = event.clientX;
        const y = event.clientY;
        let r = false;
        let l = false;
        let u = false;
        let d = false;
        if(x > this.heroAnimations.lastX){
          r = true;
          l = false;
        } 
        else if (x < this.heroAnimations.lastX){
          r = false;
          l = true;
        }
        //find out vertical movement
        if(y > this.heroAnimations.lastY){
          d = true;
          u = false;
        } 
        else if (y < this.heroAnimations.lastY){
          d = false;
          u = true;
        }
        // 2 - find out the difference from the middle of the container 
       
        // 3 - apply that distance * a handicap to the images style
        // console.log(this.$refs.headshotLayer.getBoundingClientRect())
        const DECAY = .1;
        const midX = this.$refs.headshotLayerTop.getBoundingClientRect().x + (this.$refs.headshotLayerTop.getBoundingClientRect().width/2);
        const midY = this.$refs.headshotLayerTop.getBoundingClientRect().y + (this.$refs.headshotLayerTop.getBoundingClientRect().height/2);
        const moveX = x - midX;
        const moveY = y - midY;
        this.heroAnimations.moveHeadshotX = (moveX * DECAY) * -1;
        this.heroAnimations.moveHeadshotY = (moveY * DECAY) * -1;
        console.log(this.heroAnimations.moveHeadshotX)
        // const headshotLayers = document.querySelectorAll('.headshot-layer');
        // // console.log("headshot lyaers", headshotLayers)
        // headshotLayers.forEach((layer) => {
        //   layer.style.transform = `translateY(${moveY * DECAY}px) translateX(${moveX * DECAY}px);`;
        //   console.log("layer", layer.style.transform);

        // })
        // this.$refs.headshotLayerTop.style.transform = `translateY(${moveY}px) translateX(${moveX}px);`;
        // this.$refs.headshotLayerBottom.style.transform = `translateY(${moveY}px) translateX(${moveX}px);`;
        // this.$refs.headshotLayerBottom.style.top = moveX * DECAY;
        // console.log(this.$refs.headshotLayerTop.style);

        // calculate the amount the other should move
        // apply the styles to the header images
        this.heroAnimations.lastX = x;
        this.heroAnimations.lastY = y;
        // console.log("event", event);
      },
      setScrollPosition(){
        this.lastScrollPosition = window.scrollY;
        if(this.lastScrollPosition > this.userInterface.headerHeight){
          if(this.showScrollIndicator){
            this.showScrollIndicator = false;
          }
        }
      }
    },
    computed: {
      userInterface(){
        return this.$store.state.userInterface;
      },
      isMobileDevice(){
        return this.$store.state.isMobileDevice;
      }
    },
    mounted() {
      this.setHeaderHeight();
      window.addEventListener('resize', this.setHeaderHeight); // let our userinterface state know what the header height is
      document.addEventListener('scroll', this.setScrollPosition);
      this.heroInterval = setInterval(this.setNewHeroSubtitle, 5500); // for the typing animation
      if(!this.showHeadshotHero){
        document.addEventListener('scroll', this.animateOnScroll); // animates ui elements on scroll. 
      }
      else {
        const heroContainer = document.querySelector('#homeSection');
        // if(heroContainer && !this.isMobileDevice){
        //   heroContainer.addEventListener('mouseover', this.handleHeroHover);
        //   // heroContainer.addEventListener('mouseout', this.handleHeroOffHover);
        // }
      }
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.setHeaderHeight);
      document.removeEventListener('scroll', this.setScrollPosition)
      clearInterval(this.heroInterval);
      if(!this.showHeadshotHero){
        document.removeEventListener('scroll', this.animateOnScroll); // animates ui elements on scroll. 
      }
      // else {
      //   const heroContainer = document.querySelector('#homeSection');
      //   if(heroContainer && !this.isMobileDevice){
      //     heroContainer.removeEventListener('mouseover', this.handleHeroHover);
      //   }
      // }
    },
   
  }
</script>
<style lang="scss">
.scroll-more-indicator {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  opacity: 0.85;
  font-size: .75em;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-size: auto 300%;
  animation: loadingVertical 2s infinite;
  // animation-delay: 0.5s;
  overflow: hidden;
  background-image: linear-gradient(to bottom, transparent 20%, rgba(138, 138, 138, 1) 50%,  transparent 80%);
}

// .scroll-more-indicator::before {
//     content: '';
//     position: absolute;
//     background-image: linear-gradient(to bottom, transparent 0%, rgba(138, 138, 138, 1),  transparent 100%);
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     animation: loadingVertical 2s infinite;
//      -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   background-clip: text;
// }




// transition for fade
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-active, .fade-leave-active {
  transition: all .5s ease;
}
.fade-enter-to, .fade-leave {
  opacity: 0.85;
}

.hero {
  height: calc(100vh - 200px);
  width: 100vw;
  max-width: 100%;
  position: relative;
  overflow: hidden !important;
  // overflow-x: hidden;
  // overflow-y: visible;
  &:hover .headshot-layer:first-of-type {
    transform: scale(1.05);
    // transform-origin: right right;
  }
  &:hover .headshot-layer:last-of-type .headshot-img {
    transform: scale(1.05);
  }
}
.hero-text {
  z-index: 3;
}

.hero-img {
  display: block;
  z-index: 1;
  overflow: hidden;
  // width: 50%;
  // float: right;
}


// START: HEADSHOT HERO IMAGE //
.headshot-layer {
  position: absolute;
  width: 50%;
  height: calc(100vh - 200px);
  top: 0;
  left: 50%;
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-end;
  // align-content: center;
}

.hero-img .headshot-layer:first-of-type {
  height: 100%;
  -webkit-mask-image: url(../assets/paint-splash3-min.png);
  mask-image: url(../assets/paint-splash3-min.png);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  transition: all 1s ease;
  transform-origin: center center;
  overflow: hidden;
  // position: relative;

  &:before{
    overflow: hidden;
    background: linear-gradient(to right, var(--v-primary-base) 0%, var(--v-primary-darken2) 100%);
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    position: absolute;
  }
}

.hero-img .headshot-layer:last-of-type{
  height: 50%;
  overflow: hidden;
}

.headshot-layer .headshot-img {
  position: relative;
  z-index: 2;
  max-width: 100%;
  height: calc(100vh - 200px);
  user-select: none;
  display: block;
  // vertical-align: middle;
  // float: right;
  margin: auto;
  // border-style: none;
  object-position: center top;
  object-fit: contain;
  transition: all 1s ease;
  transform-origin: center center;
}

.headshot-scale-up {
  transform: scale(1);
  // transition: all 1s ease;
  // transform-origin: center center;
}

.headshot-scale-down {
  transform: scale(.95);
  // transition: all 1s ease;
  // transform-origin: center center;
}


// END: HEADSHOT HERO IMAGE //


// START: STOCK HERO IMAGE //


.hero .hero-img .layer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;

  #layer-1 {
    position: relative;
    height: 100%;
    user-select: none;
    margin: auto;
    z-index: 2;
    filter: drop-shadow(2px 4px 6px black);
    -webkit-filter: drop-shadow(2px 4px 6px black);
    vertical-align: middle;
    display: block;
    max-width: 100%;
    border-style: none;
    object-fit: contain;
    float: right;
  }

  #layer-2 {
    position: relative;
    z-index: 1;
    height: calc(100vh - 200px);
    user-select: none;
    margin: auto;
    vertical-align: middle;
    display: block;
    max-width: 100%;
    border-style: none;
    object-fit: contain;
  }
}



#hero-img-background {
  position: absolute;
  top: -64px;
  right: 0;
  height: 100%;
  width: 60%;
  // background-color: var(--v-primary-base);
  background-size: 200% auto;
  background-image: linear-gradient(to bottom right, var(--v-primary-base) 0%, var(--v-primary-lighten2) 35%, rgba(255,255,255,0)  70%) !important;
  background-position: 100% 100%; 
  // background: linear-gradient(171deg, var(--v-primary-base) 40%, rgba(255,255,255,0) 90%);
  z-index: 0;
  overflow: hidden;
}

#hero-img-background {
  #c1{
    background-color: var(--v-secondary-base);
    // background: radial-gradient(circle, rgba(33,33,33,1) 80%, rgba(33,33,33,0) 100%);
    height: 55%;
    width: 55%;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    transform: translate(-45%, 25%);
    transition: background 0.9s cubic-bezier(.4,0,.2,1);
  }
  #c2{
    background-color: var(--v-secondary-base);
    // background: radial-gradient(circle, rgba(33,33,33,1) 80%, rgba(33,33,33,0) 100%);
    height: 45%;
    width: 75%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    transform: translate(25%, -45%);
    transition: background 0.9s cubic-bezier(.4,0,.2,1);
  }
}

// END: STOCK HERO IMAGE //



.slideUp-enter, .slideUp-leave-to {
  position: relative;
  top: 100%;
}

.slideUp-enter-active, .slideUp-leave-active {
  transition: all 1.3s;
}

.typewriter {
  overflow: hidden;
  border-right: .15em solid var(--v-primary-base);
  white-space: nowrap;
  // margin: 0 auto;
  letter-spacing: .15em;
  // animation: 
  // blink-caret .3s step-end infinite;
  // width: 0;
}

.type-backward {
  animation: 
  typeBackward 2s steps(30, end),
  blink-caret .4s step-end infinite;
}

.type-forward {
  animation: 
  typeForward 3.5s steps(30, end),
  blink-caret .6s step-end infinite;
}


/* The typing effect */
@keyframes typeForward {
  from { width: 0 }
  to { width: 100% }
}

/* The typing effect */
@keyframes typeBackward {
  from { width: 100% }
  to { width: 0 }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: var(--v-primary-base) }
}

/* XXS - iphone 5 and 6 */
@media (max-width: 350px){
  .hero{
    height: calc(125vh - 200px);
  }
  .headshot-layer {
    width: 100%;
    left: 0;
    top: -15%;
  }
   
}


/* XS */
@media (min-width: 351px) and (max-width: 600px){
  .headshot-layer {
    width: 100%;
    left: 0;
    top: -15%;
    // position: static;
  }
  // .hero-img {
  //   opacity: .75;
  // }
   
}

/* SM */
@media (min-width: 601px) and (max-width: 960px){
  .headshot-layer {
    width: 100%;
    left: 0;
    top: -10%;
  }
 
}

/* MD */
@media (min-width: 961px) and (max-width: 1264px){
  .headshot-layer {
    width: 100%;
    left: 0;
    top: 0;
  }
  #hero-img-background {
    width: 70%;
    background: linear-gradient(171deg, var(--v-primary-base) 30%, rgba(255,255,255,0) 80%);
    #c1{
      transform: translate(-50%, 25%);
    }
    #c2{
      height: 45%;
      width: 75%;
    }
  }
 
    
}

/* LG */
@media (min-width: 1265px) and (max-width: 1904px){
  .headshot-layer {
    // width: 100%;
    right: 0;
    top: -5%;
  }
  // .hero-img .headshot-layer:first-of-type {
  //   mask-position: right;
  //   -webkit-mask-position: right;
  // }
}

/* XL */
@media (min-width: 1905px){
  .hero-img .headshot-layer:first-of-type {
    mask-position: right;
    -webkit-mask-position: right;
  }
   
}


  
</style>
