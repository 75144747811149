import Vue from 'vue'
import Vuex from 'vuex'
import Vuetify from '../plugins/vuetify'

import { collection, query, orderBy, where, getDocs, doc, updateDoc } from "firebase/firestore"; 
import firebase from "../../firebaseConfig.js";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['isAdmin', 'portfolioItems', 'adminPortfolioItems', 'userInterface'],
      key: 'dillanjohnson',
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    isMobileDevice: false,
    alert: {
      show: false,
      message: "",
      isGood: false,
    },
    userInterface: {
      headerHeight: 0,
      showMobileMenu: false,
      showTopNav: true,
    },
    isAdmin: false,
    portfolioItems: [],
    adminPortfolioItems: [],
  },
  mutations: {
    toggleDarkTheme: () => {
      Vuetify.framework.theme.dark = !Vuetify.framework.theme.dark;
    },
    toggleMobileMenu: (state) => {
      state.userInterface.showMobileMenu = !state.userInterface.showMobileMenu;
    },
    setMobileDevice: (state, payload) => {
      state.isMobileDevice = payload;
    },
    alertUser: (state, payload) => {
      // console.log(payload)
      state.alert.show = payload.show;
      state.alert.message = payload.message;
      state.alert.isGood = payload.isGood;
    },
    setHeaderHeight: (state, payload) => {
      if(payload <= 0){
        return;
      }
      state.userInterface.headerHeight = payload;
    },
    setAdmin: (state, payload) => {
      state.isAdmin = payload;
    },
    setPortfolioItems: (state, payload) => {
      state.portfolioItems = payload;
    },
    setAdminPortfolioItems: (state, payload) => {
      state.adminPortfolioItems = payload;
    },
    clearPortfolioItems: (state) => {
      state.portfolioItems = [];
    }
  },
  actions: {
    fetchPortfolioItems: async ({state, commit}) => {
      let items = [];
      let admin = [];
      const portfolioRef = collection(firebase.db, "portfolio");
      const q = query(portfolioRef, /*where("display", "==", true),*/ orderBy("endDate", "desc"));
      const snapshot = await getDocs(q);
      if (snapshot.size === 0){
        commit('setPortfolioItems', items);
        commit('setAdminPortfolioItems', admin);
      }
      else {
        commit('clearPortfolioItems');
        snapshot.forEach((doc) => {
          let i = doc.data();
          i['id'] = doc.id;
          i['startDate'] = doc.data().startDate.toDate().toLocaleDateString();
          if(doc.data().endDate !== null){
            i['endDate'] = doc.data().endDate.toDate().toLocaleDateString();
          }
          else {
            i['endDate'] = 'Current';
          }
          if(i.display === true){
            items.push(i);
          }
          admin.push(i);
        })
        commit('setPortfolioItems', items);
        commit('setAdminPortfolioItems', admin);
      }
    },
    updatePortfolioItem: async({commit, dispatch}, payload) => {
      console.log("payload = ", payload);
      let updatedDoc = {...payload};
      updatedDoc.startDate = new Date(updatedDoc.startDate);
      if(updatedDoc.endDate === 'Current'){
        updatedDoc.endDate = null;
      }
      else {
        updatedDoc.endDate = new Date(updatedDoc.endDate);
      }
      const docRef = doc(firebase.db, "portfolio",payload.id);
      await updateDoc(docRef, updatedDoc)
      .catch((err) => {
        console.log("err", err);
        commit('alertUser', {show: true, message: `Error Updating The Doc: ${err.message}`, isGood: false } );
        return;
      })
      commit('alertUser', {show: true, message: `Update Successful 😃`, isGood: true } );
      dispatch('fetchPortfolioItems');
    },
  },
  getters: {
    getProjectById: (state) => (id) => {
      if(state.isAdmin){
        return state.adminPortfolioItems.find(i => i.id === id);
      }
      return state.portfolioItems.find(i => i.id === id);
    },
    getPortfolioTypes: (state) => {
      let types = {
        'all': true,
      };
      state.portfolioItems.forEach((i) => {
        if (typeof types[`${i.type}`] === 'undefined'){
          types[`${i.type}`] = true;
        }
      })
      return Object.keys(types);
    },
    getPortfolioItemsByType: (state) => (type) => { 
      if(type === 'all' || type === "" || type === null){
        return state.portfolioItems;
      }
      return state.portfolioItems.filter( item => item.type === type);
    },
    getSimilarPortfolioItems: (state, getters) => (currentId) => {
      const MAX_ITEMS = 5;
      // 1 - get the item
      const currentItem = getters.getProjectById(currentId);
      // console.log("current Item", currentItem);
      // 2 -  get all posts that are the same type - up to MAX
      let items = state.portfolioItems.filter( item => item.type === currentItem.type && item.id !== currentItem.id);
      // 3 - if list is still < MAX, get MAX - list.LENGTH more items
      if(items.length < MAX_ITEMS){
        const moreItems = MAX_ITEMS - items.length;
        let additionalItems = state.portfolioItems.filter( item => item.type !== currentItem.type && item.id !== currentItem.id).slice(0,moreItems);
        items = items.concat(additionalItems);
      }
      return items;
    }

  },
  modules: {
  }
})
