import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            light: {
              primary: colors.orange.darken1, //#FB8C00
              secondary: colors.grey.lighten2, // #E0E0E0
              compliment: colors.grey.lighten3, // #EEEEEE
              accent: colors.grey.darken1, // #757575
              success: colors.green.darken1, // #43A047
              warning: colors.red.darken1, // #E53935
              info: colors.orange.darken2, // #F57C00
              smoke: colors.grey.darken4,
              icon: '#F5f5f5',
            },
            dark: {
              primary: colors.orange.darken3, // #EF6C00
              // primary: colors.purple.lighten1,
              secondary: colors.grey.darken4, // #212121
              compliment: '#2b2b2b',
              accent: colors.grey.darken3, // #424242
              success: colors.green.darken2, // #388E3C
              warning: colors.red.darken2, // #D32F2F
              info: colors.orange.darken4, // #E65100
              smoke: colors.grey.lighten4,
              icon: '#F5f5f5',
            },
        },
        options: {
          customProperties: true
        }
      },
});
