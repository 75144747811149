let logger = {
  isAdmin: false,
  isProduction: process.env.NODE_ENV === 'production',
  display: true,
  
  setAdmin:  function(isAdmin) {
    this.isAdmin = isAdmin;
  },

  log: function (p1, p2, p3) {
    if(this.isProduction){ // if we are in production mode, don't log
      if(!this.isAdmin){ // unless signed in as admin
        return;
      }
    }
    else if(!this.display){ // if the display is off don't log
      return;
    }
    else { // else log them bitches
      if(p3){
        console.log(p1,p2,p3);
      }
      else if (p2){
        console.log(p1,p2);
      }
      else {
        console.log(p1);
      }
    }
  }
}


export default logger;