import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/lib/services/goto'
import Home from '../views/Home.vue'
import firebase from '../../firebaseConfig'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // alias: '/home'
  },
  {
    path: '/project/:id',
    name: 'ViewProject',
    component: () => import('../views/ViewProject.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    // console.log("in scroll behavior")
    let scrollTo = 0
    if (to.hash) {
      scrollTo = to.hash
      return goTo(scrollTo)
    } 
    else if (savedPosition) {
      if(to.name === 'ViewProject' && from.name === 'ViewProject'){ // new route but diff id
        if(to.params.id !== from.params.id) { // new project, set to 0
          scrollTo = 0;
        }
        else {
          scrollTo = savedPosition.y
        }
        return goTo(scrollTo)
      }
      else if (to.name === 'ViewProject'){
        scrollTo = 0;
        return goTo(scrollTo)
      }
      else {
        scrollTo = savedPosition.y
        setTimeout(() => {
          return goTo(scrollTo)
        }, 300) // this must always be set to the same time as the router view transition!
      }
    }
    else {
      // console.log("else going to", scrollTo)
      setTimeout(() => {
        return goTo(scrollTo)
      }, 300) // this must always be set to the same time as the router view transition!
    }


    // console.log("to", to)
    // console.log("from", from);
  },
  routes
})

router.beforeEach((to, from, next) => {
  // console.log("to: ", to)
  // console.log("from", from);
  if(to.name === null){ // they have went directly to a project page, and haven't been on the site. if they click a back button we want them to stay in site
    next(false)
  }
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const currentUser = firebase.auth.currentUser;
  if (requiresAuth && !currentUser) {
      next('/login')
  }
  else {
    next()
  }
});

export default router
