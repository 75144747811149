<template>
  <div class="portfolio-item rounded-lg cursor--pointer pa-4" 
  @click="$router.push({ name: 'ViewProject', params: { id: item.id } })"
  :style='`height: ${getCardHeight}`'
  :class="[ isMobileDevice && isIntersecting ? `${item.color} show-details-on-intersection` : item.color, item.color ]" 
  v-tilt="isMobileDevice ? disableTilt : tiltOptions"
  v-intersect="{ handler: onIntersect, options: {  threshold: [0, 0.25, 0.5, 0.75, 1.0] } }"
  >

    <div class="portfolio-item-content">
      <!-- <v-img class="portfolio-item-img" cover :src="item.img"></v-img> -->
      <img class="portfolio-item-img rounded-lg" :src="item.img"/>

      <div class="portfolio-item-details">

        <div class="portfolio-item-title">
            <h3 class="title-text-md px-1">{{item.title}}</h3>
        </div>

        <div class="portfolio-item-description">
            <div class="body-text">{{item.description}}</div>
        </div>

        <div class="portfolio-item-date date-text px-1">
          {{item.endDate}}
        </div>

      </div>
    </div>

    <!-- <div class="bottom-bar-fade" :style='`background-image: linear-gradient(transparent,${getFadeColor});`'></div> -->

    <div class="js-tilt-glare rounded-lg">
      <div class="js-tilt-glare-inner"></div>
    </div>
    
  </div>
</template>
<script>
export default {
  name: "PortfolioCard",
  props: ["item", "index", "hover"],
  data: () => ({
    tiltOptions: {
      speed: 500,
      perspective: 3000,
      transition: true,
      glare: true,
      'max-glare': 0.7,
      'glare-prerender': true,
    },
    disableTilt: {
      speed: 0,
      max: 0,
      perspective: 10000000,
      transition: false,
      glare: false,
      'max-glare': 0,
      'glare-prerender': true,
    },
    isIntersecting: false,
  }),
  methods: {
    onIntersect(entries, observer){
      // console.log(entries[0].intersectionRation)
      // console.log(observer)
      // console.log(entries[0].intersectionRatio)
      this.isIntersecting = entries[0].intersectionRatio >= 0.50;
      // console.log(this.item.title, ":", this.isIntersecting);
    },


  },
  created(){
    // console.log("feature: ", this.feature);
  },
  computed: {
    getCardHeight(){
      switch (this.item.size){
        case 'small':
          return '220px !important';
          break;
        case 'medium':
          return '300px !important'
          break;
        case 'large':
          return '400px !important'
          break;
        default:
          return '250px !important'
          break;
      }
    },
    getFadeColor(){
      const s = this.item.color.split(" ");
      return s[0]
      // if(s.length === 1){
      //   return `${s[0]}-base`;
      // }
      // else if (s.length === 2){
      //   return `${s[0]}-${s[1]}`
      // }
      // else {
      //   return `${s[0]}-base`;
      // }
    },
    isMobileDevice(){
      return this.$store.state.isMobileDevice;
    }
  },
}
</script>
<style lang="scss">

.portfolio-item {
  position: relative;
  margin-bottom: 20px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  overflow: hidden;
  min-height: 200px;
  max-height: 500px;
  // transition: all 0.3s ease-out;
  &:hover { 
    .portfolio-item-img {
      opacity: .65;
    }
    .portfolio-item-title {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.3s ease-out;
      visibility: visible;
    }
    .portfolio-item-description {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.6s ease-out;
      display: inline-block;
      visibility: visible;
    }
    .portfolio-item-date {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.3s ease-out;
      visibility: visible;
    }
  }
}
.show-details-on-intersection {
  .portfolio-item-img {
    opacity: .65;
  }
  .portfolio-item-title {
    transition: all 0.3s ease-out;
    opacity: 1;
    transform: translateY(0);
    display: inline-block;
    visibility: visible;
  }
  .portfolio-item-description {
    transition: all 0.6s ease-out;
    opacity: 1;
    transform: translateY(0);
    display: inline-block;
    visibility: visible;
  }
  .portfolio-item-date {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease-out;
    visibility: visible;
  }
}


.portfolio-item-content {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.portfolio-item-img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  // right: 0;
  // bottom: 0;
  overflow: hidden;
  object-fit: contain;
  object-position: center;
  image-orientation: from-image;
  opacity: .25;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

.portfolio-item-details {
  position: absolute;
  z-index: 4;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  max-height: 80%;
  overflow: hidden;
}

.portfolio-item-title {
  display: inline-block;
  visibility: hidden;
  background: var(--v-secondary-base);
  opacity: 0;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transform: translateY(20px);
}

.portfolio-item-description {
  display: inline-block;
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s ease-out;
  -webkit-transition: all 0.6s ease-out;
  transform: translateY(20px);
}

.portfolio-item-date {
  display: inline-block;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transform: translateY(20px);
  background: var(--v-secondary-base);
}

.js-tilt-glare {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.js-tilt-glare-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 100%);
    width: 300%;
    height: 300%;
    overflow: hidden;
    // transform: rotate(180deg) translate(-50%, -50%) scale(3);
    transform-origin: 0% 0%;
    pointer-events: none;
    opacity: 0;
}

.bottom-bar-fade {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 20px;
  width: 100%;
}

    
</style>