<template>
<v-hover v-slot="{hover}">
  <div class="rounded-lg feature-card compliment cursor--pointer d-flex justify-center align-center px-3 full-width" :class="[ hover ? 'elevation-16' : 'elevation-6']" @click="$vuetify.goTo(feature.href, scrollOptions)" >
    <v-row justify="space-between" align="center" class="flex-nowrap mx-0">
      
      <v-icon medium class="icon--text primary rounded-circle pa-2 hidden-xs-only" >{{feature.icon}}</v-icon>
      <v-icon small class="icon--text primary rounded-circle pa-2 hidden-sm-and-up" >{{feature.icon}}</v-icon>

      <v-col cols="9" xl="8" lg="9" md="9" sm="9" xs="9">
        <div class="title-text-lg text-center primary--text">{{feature.title}}</div>
        <div class="d-flex flex-column justify-center align-center">
          <div class="caption-text text-center smoke--text" v-for="(subtitle,index) in feature.subtitle" :key="index">{{subtitle}}</div>
        </div>
      </v-col>

      <v-icon class="feature-card-icon">mdi-chevron-right</v-icon>

    </v-row>
  </div>
</v-hover>
</template>
<script>
export default {
  name: "FeatureCard",
  props: ["feature"],
  data: () => ({
    scrollOptions: {
      duration: 500,
      easing: 'easeInOutCubic',
      offset: 0,
    },

  }),
  methods: {

  },
  created(){
    // console.log("feature: ", this.feature);
  },
  computed: {

  },
}
</script>
<style lang="scss">

.feature-card-container:hover .feature-card {
  transform: translateY(-15px);
  // box-shadow: 0px 0px 25px var(--v-accent-base);

  .feature-card-icon {
      color: var(--v-smoke-lighten4) !important;
    }
}

.feature-card {
    height: 150px;
    // box-shadow: 1px 1px 14px var(--v-accent-base);
    display: block;
    transition: all .3s ease-out;
    .feature-card-icon {
      color: var(--v-smoke-darken2) !important;
    }
}

.feature-card-icon {
  color: var(--v-smoke-base) !important;
}



    
</style>