<template >
    <div>
      <v-btn v-if="!onViewProjectPage" icon fixed right bottom large class="primary" style="z-index: 6;" @click="$store.commit('toggleMobileMenu'), setMobileMenuPosition(), eventFired('mobile_menu_opened')">
        <v-icon v-if="!showMobileMenu" class="white--text" >mdi-menu</v-icon>
        <v-icon v-else class="white--text">mdi-close</v-icon>
      </v-btn>

      <v-btn v-if="onViewProjectPage" icon fixed right bottom large class="primary" style="z-index: 6;" @click="$router.push({ path: '/' })">
        <v-icon class="white--text">mdi-home</v-icon>
      </v-btn>

      <transition name="scaleBoth">
        <nav v-if="showMobileMenu" ref="mobileNavContainer" id="mobile-nav-container" :class="{'open-state' : showMobileMenu}">
          <div v-for="(nav, index) in navItems" :key="index" ref="mobileNavItem" class="pa-2 primary mobile-nav-item cursor--pointer" :class="{'open-state' : showMobileMenu}" @click="$store.commit('toggleMobileMenu'), mobileNavClicked(nav), $emit('changingRoute')">
            <v-icon class="nav-item-icon" x-large>{{nav.icon}}</v-icon>
            <div class="text-decoration-none font-weight-bold text-caption text-center grey--text text--lighten-4">{{nav.text}}</div>
          </div>
          <!-- <div v-if="isAdmin" ref="mobileNavItem" class="pa-2 primary mobile-nav-item cursor--pointer" :class="{'open-state' : showMobileMenu}" @click="$store.commit('toggleMobileMenu'), $route.push({ name: 'Admin'}), $emit('changingRoute')">
            <v-icon class="nav-item-icon" x-large>mdi-cogs</v-icon>
            <div class="text-decoration-none font-weight-bold text-caption text-center smoke--text">Admin</div>
          </div> -->
        </nav>
      </transition>

      <transition name="overlay-fade">
        <v-overlay v-show="showMobileMenu" absolute z-index="4" opacity=".95" id="mobile-menu-overlay"></v-overlay>
      </transition>
    </div>
</template>
<script>
import { logEvent } from "firebase/analytics";
import firebase from '../../firebaseConfig.js'

export default {
  name: 'MobileNav',
  props: ["navItems"],
  data() {
    return {
      scrollOptions: {
        duration: 500,
        easing: 'easeInOutCubic',
        offset: 0,
      },
      hasSetMobileButtonSize: false,
      buttonSize: 40, // (units = px) - the min size we'd ever want a button
    }
  },
  methods: {
    setMobileMenuPosition(){
      // 0. GET THE ITEMS PLACED ON THE SCREEN DYNAMICALLY AND CHOOSE BUTTON SIZE DYNAMICALLY
      if(this.showMobileMenu){
        process.nextTick(() => {
          this.setMobileButtonSize();
          const radius = typeof this.$refs.mobileNavContainer !== 'undefined' &&  this.$refs.mobileNavContainer.clientWidth !== 0 ? this.$refs.mobileNavContainer.clientWidth / 2 : 230;
          // console.log("radius", radius);
          const buttonWidth = typeof this.$refs.mobileNavItem[0] !== 'undefined' && this.$refs.mobileNavItem[0].clientWidth !== 0 ?  this.$refs.mobileNavItem[0].clientWidth : 62;
          const startDegree = 270 - this.pixelsToDegrees(radius, buttonWidth/2, 90);
          const endDegree = 180 + this.pixelsToDegrees(radius, buttonWidth/2, 90); // buttonWidth / 2 because we translate it -50%
          // console.log("start ", startDegree)
          // console.log("end",  endDegree);
          const separation = (startDegree - endDegree) / (this.$refs.mobileNavItem.length - 1); // length - 2 because we will place the start and end one.
          // console.log("sep", separation)
          for(let i = 0; i < this.$refs.mobileNavItem.length; i++){
            let degrees = startDegree - (i * separation);
            if (i === this.$refs.mobileNavItem.length - 1){
              this.$refs.mobileNavItem[i].style.transform = `rotate(${degrees}deg) translate(${radius}px) rotate(${degrees * -1}deg) translateX(-50%) translateY(-50%)`;
            }
            else {
              this.$refs.mobileNavItem[i].style.transform = `rotate(${degrees}deg) translate(${radius}px) rotate(${degrees * -1}deg) translateX(-50%) translateY(-50%)`;
            }
            this.$refs.mobileNavItem[i].style.transitionDelay = `${i * 0.03}s`
          }
        })
      }
      else {
        for(let i = 0; i < this.$refs.mobileNavItem.length; i++){
            // this.$refs.mobileNavItem[i].style.transform = `rotate(0deg) translateX(-40%) translateY(-40%) rotate(0deg)`;
            this.$refs.mobileNavItem[i].style.transform = '';
            const delay = this.$refs.mobileNavItem.length - i;
            this.$refs.mobileNavItem[i].style.transitionDelay = `${delay * 0.01}s`
          }
        }
    },
    pixelsToDegrees(wrapperRadius, buttonRadius, totalDegrees){
      const dpp = totalDegrees / wrapperRadius; // degrees per pixel
      return buttonRadius * dpp; // button radius / 2 
    },
    overlayClicked(){
      if(this.showMobileMenu){
        this.$store.commit('toggleMobileMenu');
      }
    },
    setMobileButtonSize(){
      if(this.hasSetMobileButtonSize){
        // if we have already set the size 
        for(let i = 0; i < this.$refs.mobileNavItem.length; i++){
          this.$refs.mobileNavItem[i].style.width = `${this.buttonSize}px`;
          this.$refs.mobileNavItem[i].style.height = `${this.buttonSize}px`;
        }
      }
      else {
        // 1 - find out the max size each button can take up
        const radius = typeof this.$refs.mobileNavContainer !== 'undefined' &&  this.$refs.mobileNavContainer.clientWidth !== 0 ? this.$refs.mobileNavContainer.clientWidth / 2 : 230;
        const arc_length = 90 * (Math.PI / 180) * radius;
        const max_button_size = arc_length / this.$refs.mobileNavItem.length;
        const available_space_with_padding = arc_length - max_button_size; // we subtract the max button size because we want to give a half a button size padding on each end of the arch
        const button_size = available_space_with_padding / this.$refs.mobileNavItem.length;

        // 2 - set each button to that size.
        for(let i = 0; i < this.$refs.mobileNavItem.length; i++){
          this.$refs.mobileNavItem[i].style.width = `${button_size}px`;
          this.$refs.mobileNavItem[i].style.height = `${button_size}px`;
        }
        // 3 - set the button size tracker to the calculated size and let the component know we have ran the calculations on this screen size
        this.buttonSize = button_size;
        this.hasSetMobileButtonSize = true;
      }
    },
    eventFired(name){ // for firebase analytics
      if(this.showMobileMenu){
        // console.log(firebase.analytics)
        if(this.isAdmin || process.env.NODE_ENV === 'development'){
          return;
        }
        logEvent(firebase.analytics, name);
      }
    },
    mobileNavClicked(navItem){
      if(navItem.text === 'Admin'){
        console.log("admin clicked")
        this.$router.push({ name: 'Admin'}).catch(() => console.log(""));
      }
      else {
        this.$vuetify.goTo(navItem.href, this.scrollOptions)
      }
    }

  },
  computed: {
    showMobileMenu(){
      return this.$store.state.userInterface.showMobileMenu;
    },
    onViewProjectPage(){
      return this.$route.name === 'ViewProject';
    },
    isAdmin(){
      return this.$store.state.isAdmin;
    }
  },
  watch: {
    isAdmin(newValue, oldValue){
      console.log("new value for is admin", newValue)
      console.log("old value", oldValue)
      if(newValue !== oldValue){ // we add and take away the admin button, therefore when we do we need to recalculate the size of the buttons!
        this.hasSetMobileButtonSize = false;
      }
    }
  },
  mounted(){
    const overlay = document.querySelector('#mobile-menu-overlay');
    if(overlay != null){
      overlay.addEventListener('click', this.overlayClicked)
    }
    // set an event listener for the window resizer so that we can tell the mobile button calculations to run again for the new window size
    window.addEventListener('resize', () => { this.hasSetMobileButtonSize = false;});
  },
  beforeDestroy() {
    const overlay = document.querySelector('#mobile-menu-overlay');
    if(overlay != null){
      overlay.removeEventListener('click', this.overlayClicked)
    }
    window.removeEventListener('resize', () => { this.hasSetMobileButtonSize = false;});
    
  },
}
</script>
<style lang="scss">

#mobile-nav-container {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  will-change: transform;
  transform-origin: center;
  border-radius: 50%;
  z-index: 5;
  background-image: radial-gradient(circle, transparent, transparent 100%);

  .mobile-nav-item {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-40%) translateX(-40%);
    // width: 3.25em !important;
    // height: 3.25em !important;
    border-radius: 50%;
    will-change: transform;
    opacity: 1;
    transition: all .2s ease-out;

    div{ // text inside button
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-shadow: 1px 1px 3px rgba(34, 34, 34, 0.2)
    }
    .nav-item-icon {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      top: 0;
      left: 0;
      opacity: 0.15;
    }
  }
}


.mobile-nav-item.open-state {
  z-index: 5;
  // transform: scale(1);
  // animation: mobileNavAnimation 1s ease-out;
  // transform: scaleX(1) scaleY(1) translateY(-40%) translateX(-40%);
}

#mobile-nav-container.open-state {
  width: 28em;
  height: 28em;
  transform: scale(1) translate(50%, 50%);
  background-image: radial-gradient(circle, var(--v-accent-lighten3) 0%, transparent 70%);
}

/* XS */
@media (max-width: 600px){
    #mobile-nav-container.open-state {
      width: 26em;
      height: 26em;
    }

}

/* SM */
@media (min-width: 601px) and (max-width: 960px){
  #mobile-nav-container.open-state {
    width: 28em;
    height: 28em;
  }
}

/* MD */
@media (min-width: 961px) and (max-width: 1264px){
  #mobile-nav-container.open-state {
    width: 28em;
    height: 28em;
  }
    
}

// MOBILE NAV TRANSITION CLASSES

// nav wrapper
.scaleBoth-enter, .scaleBoth-leave-to {
  transform: scale(0) translate(50%, 50%);
  opacity: 0.2;
}

.scaleBoth-enter-active {
  transition: all .3s ease-out;
}

.scaleBoth-leave-active {
  transition: all .2s ease-out;
}

.scaleBoth-enter-to, .scaleBoth-leave {
  opacity: 1;
  transform: scale(1) translate(50%, 50%);
}

// overlay when mobile menu is open
.overlay-fade-enter, .overlay-fade-leave-to {
  opacity: 0;
}

.overlay-fade-enter-active {
  transition: all .3s ease-out;
}

.overlay-fade-leave-active {
  transition: all .2s ease-out;
}

.overlay-fade-enter-to, .overlay-fade-leave {
  opacity: .95;
}

    
</style>