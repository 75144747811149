<template>
<!-- :class="[ showAlert ? 'enter--snackbar' : 'leave--snackbar']" -->
<transition name="slideY">

  <div v-if="showAlert"  class="base--snackbar accent pa-2 rounded-lg d-flex flex-column justify-space-between align-start">
      <div class="full-width d-flex justify-center align-center">
        <v-icon v-if="!alertUser.isGood" class="warning--text">mdi-alert-circle</v-icon>
        <v-icon v-else class="success--text">mdi-check-circle</v-icon>
      </div>
      <div class="smoke--text text-subtitle-2 text-center full-width">{{ alertUser.message }}</div>
      <v-btn color="primary" small class="align-self-end" text @click="$store.commit('alertUser', {show: false, message: '', isGood: false})">Close</v-btn>
  </div>

</transition>

  <!-- <v-snackbar app absolute outlined timeout="115000" top v-if="showAlert" width="200" style="z-index: 1500;">
    {{ alertUser.message }}
    <template v-slot:action="{ attrs }">
      <v-btn color="primary" text v-bind="attrs" @click="$store.commit('alertUser', {show: false, message: ''})"></v-btn>
    </template>
  </v-snackbar> -->
</template>
<script>
export default {
  name: 'Alert',
  props: ["showAlert"],
  data: () => ({
    
  }),
  computed: {
    alertUser(){
      return this.$store.state.alert;
    }
  }
    
}
</script>
<style scoped>
.base--snackbar {
  width: 300px;
  min-height: 64px;
  position: fixed;
  z-index: 15;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.slideY-enter {
  opacity: 0.5;
  transform: translateY(100%) translateX(-50%);
  left: 50%;
}

.slideY-enter-to {
  opacity: 1;
  transform: translateY(0) translateX(-50%);
  left: 50%;
}

.slideY-enter-active {
  transition: all .4s ease-out;
}

.slideY-leave-to {
  opacity: 0;
  transform: translateX(-100%) ;
}

.slideY-leave-active {
  transition: all .4s ease-in;
}

.enter--snackbar {
  animation: slideUp .3s ease-out;
}

.leave--snackbar {
  animation: slideDown .3s ease-in;
}

@keyframes slideDown {
  0% { 
    bottom: 10px;
    opacity: 1;
  }
  100% {
    bottom: -64px;
    opacity: .5;
  }
}

@keyframes slideUp {
  0% { 
    bottom: -64px;
    opacity: .5;
  }
  100% {
    bottom: 10px;
    opacity: 1;
  }
}
    
</style>