<template>
    <div class="full-height px-3 red-border">
      <div class="d-flex flex-row justify-center align-start">
        <div class="portfolio-items-grid">

        <SectionTextBlock :rotatedText="textBlocks[0].rotatedText" :heading="textBlocks[0].heading" :subheading="textBlocks[0].subheading" :body="textBlocks[0].body"/>
          <div class="full-width d-flex flex-row flex-wrap align-center justify-center justify-sm-end py-4">
            <div v-for="(type, i) in portfolioTypes" :key="type" class="cursor--pointer text-subtitle-1 text-sm-subtitle-2" :class="[i === filterIndex ? 'primary--text' : 'smoke--text']" @click="filterIndex = i, filterType = type">{{type}} <span class="mx-1 smoke--text" v-if="i !== portfolioTypes.length - 1" >/</span></div> 
          </div>
          <!-- <div class="my-4"></div> -->

          <template v-for="(item, i) of getPortfolioItemsByType">
            <!-- <v-hover v-slot="{hover}" :key="i"> -->
              <PortfolioCard :key="i" :index="i" :item="item" :class="[ i == 0 ? 'mt-4' : '']" />
            <!-- </v-hover> -->
          </template>

        </div>
      </div>
  </div>
</template>
<script>
import PortfolioCard from './PortfolioCard.vue'
import SectionTextBlock from './SectionTextBlock.vue'
export default {
    name: "Portfolio",
    components: {
      PortfolioCard,
      SectionTextBlock
    },
    data: () => ({
      textBlocks: [
        {
          rotatedText: "Projects",
          heading: "My Work",
          subheading: "",
          body: "I  create the best quality websites with long term maintainability in mind. Each project contains a clean, well-documented, easy and elegant interface which helps any non-technical clients. Here are some professional and hobby projects.",
        },
      ],
      filterType: "all",
      filterIndex: 0, // all
    }),
    methods: {
      shuffleItems(){
        for (let i = this.portfolioItems.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          const temp = this.portfolioItems[i];
          this.portfolioItems[i] = this.portfolioItems[j];
          this.portfolioItems[j] = temp;
        }
      }
    },
    mounted(){
     
    },
    created(){
      // this.shuffleItems()

    },
    beforeDestroy() {
    },
    computed: {
      portfolioItems(){
        return this.$store.state.portfolioItems;
      },
      portfolioTypes(){
        return this.$store.getters.getPortfolioTypes;
      },
      getPortfolioItemsByType(){
        return this.$store.getters.getPortfolioItemsByType(this.filterType);
      }
    },
}
</script>
<style>

.portfolio-items-grid {
  position: relative;
  width: 100%;
  max-width: 100%;
  /* height: 80vh; */
  column-count: 3;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-gap: 20px;
}

/* XS */
@media (max-width: 600px){
    .portfolio-items-grid {
      column-count: 1;
      -webkit-column-count: 1;
      -moz-column-count: 1;
    }

}

/* SM */
@media (min-width: 601px) and (max-width: 960px){
  .portfolio-items-grid {
      column-count: 2;
      -webkit-column-count: 2;
      -moz-column-count: 2;
    }
}

/* MD */
@media (min-width: 961px) and (max-width: 1264px){
  .portfolio-items-grid {
      column-count: 2;
      -webkit-column-count: 2;
      -moz-column-count: 2;
    }
    
}

/* LG */
@media (min-width: 1265px) and (max-width: 1904px){
   
}

/* XL */
@media (min-width: 1905px){
    
}


</style>
