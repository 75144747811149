<template>
  <!-- <transition name="slideY"> -->
    <div class="d-flex flex-md-row flex-column align-md-start align-start justify-start full-height full-width">
      <div class="hidden-sm-and-down rotated-text rotate-180 text-subtitle-1 mt-3 mr-3 smoke--text">{{rotatedText}}</div>
      <div class="text-subtitle-2 smoke--text d-flex flex-row justify-start align-center">
          <div class="hidden-md-and-up long-line mr-4 flex-grow-1 smoke"></div>
          <span class="hidden-md-and-up">{{rotatedText}}</span>
          <v-spacer class="hidden-md-and-up"></v-spacer>
      </div>

      <!-- <v-col cols="12" sm="12" xs="12" class="pa-0"> -->
        <div class="d-flex flex-column">
          <h1 class="primary--text hidden-xs-only heading-text">{{heading}}</h1>
          <h3 class="primary--text hidden-sm-and-up heading-text">{{heading}}</h3>
          <h4 v-if="subheading != ''" class="primary--text text--darken-2 my-2 hidden-xs-only subheading-text">{{subheading}}</h4>
          <h5 v-if="subheading != ''" class="primary--text text--darken-2 my-2 hidden-sm-and-up subheading-text">{{subheading}}</h5>
          <div class="smoke--text text--darken-1 body-text full-width">{{body}}</div>
        </div>
       <!-- </v-col> -->
    </div>

  <!-- </transition> -->
</template>
<script>
export default {
  name: 'SectionTextBlock',
  props: ["rotatedText", "heading", "subheading", "body"],
  data: () => ({
    
  }),
  computed: {
    
  }
    
}
</script>
<style scoped>

.slideY-enter {
  opacity: 0.5;
  transform: translateY(100%) translateX(-50%);
  left: 50%;
}

.slideY-enter-to {
  opacity: 1;
  transform: translateY(0) translateX(-50%);
  left: 50%;
}

.slideY-enter-active {
  transition: all .4s ease-out;
}

.slideY-leave-to {
  opacity: 0;
  transform: translateX(-100%) ;
}

.slideY-leave-active {
  transition: all .4s ease-in;
}

.enter--snackbar {
  animation: slideUp .3s ease-out;
}

.leave--snackbar {
  animation: slideDown .3s ease-in;
}

@keyframes slideDown {
  0% { 
    bottom: 10px;
    opacity: 1;
  }
  100% {
    bottom: -64px;
    opacity: .5;
  }
}

@keyframes slideUp {
  0% { 
    bottom: -64px;
    opacity: .5;
  }
  100% {
    bottom: 10px;
    opacity: 1;
  }
}
    
</style>