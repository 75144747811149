<template>
  <div class="full-height d-flex flex-row justify-center justify-md-space-between align-start flex-wrap full-width red-border">
     <!-- IMAGE COLUMN -->
    <v-col cols="12" xl="5" lg="5" md="12" sm="10" xs="12">
        <v-img src="../assets/dillan_johnson_valley_of_fire.jpg" lazy-src="../assets/dillan_johnson_valley_of_fire-min.jpg" ref="dsuImg" cover max-height="378"></v-img>
    </v-col>

    <!-- TEXT SECTION -->
    <v-col cols="12" xl="5" lg="6" md="12" sm="10" xs="12">
      <SectionTextBlock  :rotatedText="sectionText.rotatedText" :heading="sectionText.heading" :subheading="sectionText.subheading" :body="sectionText.body"  />
      <div class="d-flex flex-row justify-sm-end justify-space-between align-center mt-8 flex-wrap">
        <button type="button" class="hidden-xs-only button-primary button-medium mr-4" @click="$vuetify.goTo('#contactSection', scrollOptions)">Hire Me</button>
        <button type="button" class="hidden-xs-only button-primary button-medium"><a href="https://firebasestorage.googleapis.com/v0/b/dillan-portfolio.appspot.com/o/files%2Fresume%2Fdillan_johnson_full_stack.pdf?alt=media&token=471d22c0-9c55-4034-835f-782c1461a4ab" target="_blank" class="full-width full-height text-decoration-none" style="color: #f5f5f5;">View Resume</a></button>
        <!-- MOBILE - FULL WIDTH BUTTONS -->
        <button type="button" class="hidden-sm-and-up full-width button-primary button-large mb-4" @click="$vuetify.goTo('#contactSection', scrollOptions)">Hire Me</button>
        <button type="button" class="hidden-sm-and-up full-width button-primary button-large"><a href="https://firebasestorage.googleapis.com/v0/b/dillan-portfolio.appspot.com/o/files%2Fresume%2Fdillan_johnson_full_stack.pdf?alt=media&token=471d22c0-9c55-4034-835f-782c1461a4ab" target="_blank" class="full-width full-height text-decoration-none" style="color: #f5f5f5;">View Resume</a></button>
      </div>
    </v-col>
  </div>
</template>

<script>
import SectionTextBlock from './SectionTextBlock.vue'
export default {
  name: 'About',
  props: ["isIntersecting"],
  components: {
    SectionTextBlock
  },
  data(){
    return {
      sectionText: {
        rotatedText: "About Me",
        heading: "Dillan Johnson",
        subheading: null,
        body:"Hi, I'm Dillan Johnson. I'm 28 years old, I'm from Smithfield, Utah, but currently living in St. George, Utah. Some of my hobbies include fitness/working out, coding, skiing, hiking, camping (really anything outdoors), photography, and reading. While pursuing an education in graphic design, I realized my true passion was developing user experiences; not just designing them. This led me to continue my education at Dixie State University where I learned how to become a proficient Software Developer, and came to love computer programming of all kinds. To allow myself more time to study during the school year I worked during the summer months; I learned sales skills and became a successful door to door salesman for one of the largest pest control providers in the world."
      },
      scrollOptions: {
        duration: 500,
        easing: 'easeInOutCubic',
        offset: 0,
      },
    }
  },
  methods: {

  },
  created(){

  }
  
}
</script>
<style lang="">
  
</style>