<template>
  <div class="learning-card compliment cursor--pointer py-8 px-3">
      <!-- <div > -->
           <v-icon class="learning-card-icon">{{feature.icon}}</v-icon>
      <!-- </div> -->
      <div class="learning-card-content">
          <h1>{{feature.title}}</h1>
          <p class="text-subtitle-2">{{feature.description}}</p>
      </div>
  </div>
</template>
<script>
export default {
  name: "LearningCard",
  props: ["feature"],
  data: () => ({

  }),
  methods: {

  },
  created(){
    // console.log("feature: ", this.feature);
  },
  computed: {

  },
}
</script>
<style lang="scss">

.learning-card {
    border-radius: 10px;
    width: 100%;
    clip-path: circle(10% at 90% 20%);
    transition: all .5s ease-in-out;

    &:hover {
        clip-path: circle(75%);
    }

    h1 {
        color: white;
        margin: 0;
    }

    p {
        color: white;
        font-size: .8rem;
    }
    .learning-card-icon {
        float: right;
        color: white;
        font-weight: bold;
        transition: color .5s;
        position: relative;
        margin-right: 4%;
    }
    // &:hover .learning-card-icon {
    //     color: rgba(255,255,255,0)
    // }
}


</style>