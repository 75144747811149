<template>
    <div class="full-height d-flex flex-column flex-md-row align-center align-md-start justify-start justify-md-space-between red-border">


        <v-col cols="12" xl="5" lg="5" md="6" sm="10" xs="12">
            <div class="d-flex flex-md-row flex-column align-md-start align-start justify-start full-height">
                <!-- <div class="hidden-sm-and-down rotated-text rotate-180 text-subtitle-1 mt-3 mr-3 smoke--text">Contact</div>
                <div class="text-subtitle-2 mt-3 smoke--text d-flex flex-row justify-start align-center">
                    <div class="hidden-md-and-up long-line mr-4 flex-grow-1 smoke"></div>
                    <span class="hidden-md-and-up ">Contact</span>
                    <v-spacer class="hidden-md-and-up"></v-spacer>
                </div> -->
                <v-col cols="12" sm="12" xs="12" class="pa-0">
                    <SectionTextBlock :rotatedText="sectionText.rotatedText" :heading="sectionText.heading" :subheading="sectionText.subheading" :body="sectionText.body" />
                    <!-- <h1 class="primary--text hidden-xs-only heading-text">Free Estimates</h1>
                    <h3 class="primary--text hidden-sm-and-up heading-text">Free Estimates</h3>
                    <h4 class="primary--text text--darken-2 mt-2 mb-3 hidden-xs-only subheading-text">Send me a message for a FREE estimate</h4>
                    <h5 class="primary--text text--darken-2 mt-2 mb-3 hidden-sm-and-up subheading-text">Send me a message for a FREE estimate</h5>
                    <div class="smoke--text text--darken-1 body-text">Whether you have the next great idea, or want to enhance your business's online presence, or any other inquiries about a project, the first step is finding out what kind of technologies will be required to turn your dream into a reality. Fill out the form to send me a message, I will reach out asap to discuss project details with you as well as give you a free estimate.</div> -->
                </v-col>
            </div>

            <div class="d-flex flex-column full-width mt-6">
                <div class="contact-info mt-4">
                    <div class="full-width d-flex flex-row justify-start align-center">
                        <v-icon small>mdi-map-marker</v-icon>
                        <div class="ml-2 list-item-heading">Address</div>
                    </div>
                    <div class="body-text opacity-5 ml-6">The Pipeline at Atwood Innovation Plaza, 453 S 600 E, St. George, UT 84770</div>
                </div>

                <div class="contact-info mt-4">
                    <div class="full-width d-flex flex-row justify-start align-center">
                        <v-icon small>mdi-phone</v-icon>
                        <div class="ml-2 list-item-heading">Phone</div>
                    </div>
                    <div class="body-text opacity-5 ml-6">435-512-XXXX</div>
                </div>

                <div class="contact-info mt-4">
                    <div class="full-width d-flex flex-row justify-start align-center">
                        <v-icon small>mdi-email</v-icon>
                        <div class="ml-2 list-item-heading">Email</div>
                    </div>
                    <div class="body-text opacity-5 ml-6">johnsondillan@gmail.com <br> admin@dillanjohnson.com </div>
                </div>

                <div class="contact-info mt-6">
                    <v-row class="px-5 full-width mx-0" justify="start" align="center">
                        <a target="_blank" href="https://github.com/dillanj" alt="" class="contact-info-icon smoke">
                            <v-icon class="secondary--text">mdi-github</v-icon>
                            <div class="contact-tooltip white--text font-weight-bold caption-text rounded primary elevation-6">Github
                                <div class="contact-tooltip-arrow primary"></div>
                            </div>
                        </a>

                        <a target="_blank" href="https://www.linkedin.com/in/dillan-johnson-4a0375a4" class="contact-info-icon smoke">
                            <v-icon class="secondary--text">mdi-linkedin</v-icon>
                            <div class="contact-tooltip white--text font-weight-bold caption-text rounded primary elevation-6">LinkedIn
                                <div class="contact-tooltip-arrow primary"></div>
                            </div>
                        </a>
                    </v-row>
                </div>
            </div>
        </v-col>


        <v-col cols="12" xl="5" lg="5" md="6" sm="8" xs="12" class="align-self-center align-self-md-start mt-10 mt-md-0">

            <ValidationObserver ref="formObserver" v-slot="{valid}">
                <v-form class="contact-form compliment full-width px-3 py-1 px-sm-4 py-sm-2 px-md-8 py-md-3 px-lg-14 py-lg-4 elevation-6" ref="form" @submit.prevent="submitForm" v-model="formIsValid">
                    <div v-if="formLoading" class="loading-overlay">
                        <v-progress-circular class="smoke--text" indeterminate></v-progress-circular>
                    </div>
                    <div class="form-input-wrapper">
                        <label for="contact-form-name">Name</label>
                            <div class="input-control">
                                <ValidationProvider v-slot="{errors, valid}" rules="required|alpha_spaces">
                                    <input v-model="formInputs.name" :class="{'input-unvalidated': !valid && errors.length > 0}" type="text" required inputmode="text" name="Name" id="contact-form-name" placeholder="Jane Doe">
                                    <v-icon class="input-icon" :class="{'red--text': !valid && errors.length > 0}" size="20px">mdi-account</v-icon>
                                    <span v-for="(error, index) in errors" :key="index" class="input-error font-weight-bold warning--text">{{error}}</span>
                                </ValidationProvider>
                            </div>
                    </div>

                    <div class="form-input-wrapper">
                        <label for="contact-form-email">Email</label>
                        <div class="input-control">
                            <ValidationProvider v-slot="{errors, valid}" rules="required|email">
                                <input v-model="formInputs.email" :class="{'input-unvalidated': !valid && errors.length > 0}" type="email" inputmode="email" required name="Email" id="contact-form-email" placeholder="JaneDoe@example.com">
                                <v-icon class="input-icon" :class="{'red--text': !valid && errors.length > 0}" size="20px">mdi-email</v-icon>
                                <span v-for="(error, index) in errors" :key="index" class="input-error font-weight-bold warning--text">{{error}}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="form-input-wrapper">
                        <label for="contact-form-phone">Phone <span class="font-italic">(Optional)</span></label>
                        <div class="input-control">
                            <ValidationProvider v-slot="{errors, valid}" :rules="{regex: phoneRegEx}">
                                <input v-model="formInputs.phone" type="tel" :class="{'input-unvalidated': !valid && errors.length > 0}" inputmode="tel" placeholder="123-456-7890" name="Phone" id="contact-form-phone" v-on:keyup="phoneMask">
                                <v-icon class="input-icon" :class="{'red--text': !valid && errors.length > 0}" size="20px">mdi-phone</v-icon>
                                <span v-for="(error, index) in errors" :key="index" class="input-error font-weight-bold warning--text">{{error}}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="form-input-wrapper">
                        <label for="contact-form-message">Message</label>
                        <div class="input-control">
                            <ValidationProvider v-slot="{errors, valid}" ref="messageInput" rules="required" mode="aggressive">
                                <textarea v-model="formInputs.message" data-vv-validate-on="change" :class="{'input-unvalidated': !valid && errors.length > 0}" type="text" name="Message" id="contact-form-message" placeholder="Project description and questions go here."></textarea>
                                <v-icon id="message-icon" class="input-icon" :class="{'red--text': !valid && errors.length > 0}" size="20px">mdi-message</v-icon>
                                <span v-for="(error, index) in errors" :key="index" class="input-error font-weight-bold warning--text">{{error}}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="submit-button full-width form-input-wrapper">
                        <input type="submit" value="SEND" :disabled="!valid" class="full-width smoke--text font-weight-bold" :class="[valid ? 'primary' : 'grey lighten-2 grey--text text--darken-4 cursor--not-allowed']">
                    </div>
                </v-form>
            </ValidationObserver>
        </v-col>
        
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate';
import { required, email, alpha_spaces, digits, regex } from 'vee-validate/dist/rules';
import { addDoc, collection } from "firebase/firestore"; 
import firebase from '../../firebaseConfig.js'
import { logEvent } from "firebase/analytics";
import SectionTextBlock from "./SectionTextBlock.vue";

// RULE EXTENDS //
setInteractionMode('eager');
extend('required', {
    ...required,
    message: 'This field is required'
})
extend('alpha_spaces', {
    ...alpha_spaces,
    message: '{_field_} must only contain alpha characters.'
})
extend('email', {
  ...email,
  message: 'Email must be valid',
})
extend('digits', {
  ...digits,
  message: 'Phone number must be 10 digits.',
})
extend('regex', {
  ...regex,
  message: 'Phone number must be 10 digits.',
})
// // RULE EXTENDS //

export default {
    name: "Contact",
    components: {
        ValidationProvider,
        ValidationObserver,
        SectionTextBlock,
    },
    data: () => ({
        formIsValid: false,
        phoneRegEx: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        formInputs: {
            name: null,
            email: null,
            phone: "",
            message: null,
        },
        formLoading: false,
        sectionText: {
            rotatedText: "Contact",
            heading: "Free Estimates",
            subheading: "Send me a message for a FREE estimate",
            body: "Whether you have the next great idea, or want to enhance your business's online presence, or any other inquiries about a project, the first step is finding out what kind of technologies will be required to turn your dream into a reality. Fill out the form to send me a message, I will reach out asap to discuss project details with you as well as give you a free estimate.",
        }

    }),
    methods: {
        eventFired(name){
            logEvent(firebase.analytics, name);
        },
        validateForm(){
            // console.log("validate form called!");
            return this.$refs.formObserver.validate();
        },
        resetForm(){
            console.log("resetForm()")
            this.formInputs = {
                name: null,
                email: null,
                phone: "",
                message: null,
            }
            this.$refs.form.reset();
        },
        resetValidation(){
            // console.log("resetValidation()")
            this.$refs.formObserver.reset();
        },
        async submitForm(){
            // console.log("submit called")
            this.eventFired('submit_contact_form')
            this.formLoading = true;
            const valid = await this.validateForm();
            if(valid){
                const res = await this.saveContactInfo().catch((err) => { 
                    console.log("error saving contact info, err", err.message);
                    this.formLoading = false;
                    return;
                });
                if(res.saved){
                    // console.log(res.message);
                    this.resetForm();
                    this.resetValidation();
                    this.$store.commit( 'alertUser', { show: true, message: res.message, isGood: true, } );
                }
            }
            this.formLoading = false;
        },
        saveContactInfo(){
            return new Promise((resolve, reject) => {
                // console.log("saving contact info: ", this.formInputs);
                addDoc(collection(firebase.db, "messages"), {
                    name: this.formInputs.name,
                    email: this.formInputs.email,
                    phone: this.formInputs.phone !== "" ? this.formInputs.phone : null,
                    message: this.formInputs.message,
                    opened: false,
                    date: new Date(),
                })
                .then(() => {
                    resolve({ saved: true, message: "Successfully sent your message. I will reach out in the future."});
                })
                .catch((err) => {
                    console.log("There was an error saving the message.", err.message);
                    reject({ saved: false, message: "We could not save the contact info."});
                })
            })
        },
        phoneMask(){
            let num = this.formInputs.phone.replace(/\D/g,'');
            if(this.formInputs.phone.length == 0){
                return;
            }
            else if(num.length > 1 && num.length <= 3){
                this.formInputs.phone = num;
            }
            else if (num.length > 3 && num.length <= 6){
                this.formInputs.phone = `${num.substring(0,3)}-${num.substring(3,6)}`;
            }
            else if (num.length > 6 && num.length <= 10){
                this.formInputs.phone = `${num.substring(0,3)}-${num.substring(3,6)}-${num.substring(6,10)}`;
            }
            else {
                this.formInputs.phone = `${num.substring(0,3)}-${num.substring(3,6)}-${num.substring(6,10)}`;
            }
            
        }


    },
    created(){
       
    },
    computed: {

    },
}
</script>
<style scoped lang="scss">
$icon-size: 40px;

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(43, 43, 43, 0.8);
    border-radius: 10px;
    z-index: 3;
}

.contact-form {
    border-radius: 10px;
    // box-shadow: 1px 1px 14px var(--v-secondary-darken1);
    position: relative;
    input,textarea {
        width: 100%;
        background-color: var(--v-compliment-darken1);
        color: var(--v-smoke-lighten1);
        padding: .8em 1em .8em 1em;
        border-radius: 10px;
        font-size: .9rem;
        border: 1px solid transparent;
        &:focus{
            // border-inline: 5px 5px solid var(--v-primary-base);
            border: 1px solid var(--v-primary-base);
            box-shadow: 1px 1px 14px var(--v-accent-base);
            outline: none !important;
            transition: border 0.3s ease-in-out;
            color: var(--v-primary-base) !important;
            &::placeholder{
                color: var(--v-primary-base) !important;
                transition: border 0.3s ease-in-out;
            }
        }
        &::placeholder {
            color: var(--v-smoke-darken3);
        }
    }
    input {
        padding-left: $icon-size;
    }
    .input-validated {
        border: 1px solid var(--v-success-base);
    }
    .input-unvalidated {
        border: 1px solid var(--v-warning-base);

    }
    input[type=submit]{
        padding-left: 0;
        padding-right: 0;
    }
    textarea{
        resize: none;
        min-height: 120px;
        padding-left: $icon-size;
    }
    label {
        font-size: .8rem;
        font-weight: 600;
    }
}

.form-input-wrapper {
    margin-bottom: 1rem;
}

.input-control {
    position: relative;
    .input-icon {
        color: var(--v-smoke-darken3);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: $icon-size;
    }
    input:focus+.input-icon{
        color: var(--v-primary-base) !important;
        transition: all 0.3s ease-in-out;

    }
    #message-icon{
        top: 12%;
        transform: translateY(-12%);
    }
}

.input-error {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: .7rem;
}

.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}


.contact-info-icon {
    border-radius: 50%;
    width: 1.65em;
    height: 1.65em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    user-select: none;
}

.contact-tooltip {
  position: absolute;
  left: 50%;
  min-width: max-content;
  top: 0;
  transform: translate(-50%, -130%);
  animation: slideDown .3s ease;
  padding: 0.8rem 1rem;
  z-index: 1;
  display: none;
}

.contact-tooltip-arrow {
  transform: translate(-50%, 100%);
  left: 50%;
  bottom: 1px;
  height: 10px;
  width: 15px;
  -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  content: "";
  position: absolute;
  display: none;
}

.contact-info-icon:hover .contact-tooltip, .contact-tooltip-arrow {
    display: inline-block;
}

    
</style>