import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAsHIs5_73FfjFNpirwclNChgtp8Lux-yM",
  authDomain: "dillan-portfolio.firebaseapp.com",
  projectId: "dillan-portfolio",
  storageBucket: "dillan-portfolio.appspot.com",
  messagingSenderId: "74173523593",
  appId: "1:74173523593:web:7734dae04e816fbc58c858",
  measurementId: "G-Y9E6P8Q93Q"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage();;
// const rtDb = firebase.database();


export default {
  db,
  auth,
  analytics,
  storage,
  // rtDb,
};
